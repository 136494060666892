import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';
import {OrmEvents} from '../OrmEvents.js';

const PUBLIC_URL = process.env.PUBLIC_URL;

class SearchEntry extends Component{

    onQChange=(e)=>{
        let q=e.target.value;
        this.props.doChangeQ({"q":q,"start":0});
    }

    clearQ=()=>{
        OrmEvents.emit('onRemoveMarker');
        OrmEvents.emit('closePermitActions');
        this.props.doClearSearch();
    }

    render(){
        return(
            <div className='SearchText'>
                <input type="text" style={{"padding":"10px"}} className="form-control SearchInput" value={this.props.q} onChange={this.onQChange} placeholder="Search USACE Actions by Content..." aria-describedby=""/>
                <div className="ClearIcon">
                    {(this.props.q)?(
                        <div title="Clear Search Criteria" onClick={this.clearQ}>
                            <img src={`${PUBLIC_URL}/close-grey.png`} style={{"height":"15px"}}/>
                        </div>):null
                    }
                </div>
            </div>
            
        )
    }
}

export default connect(
    'doChangeQ',
    'doClearSearch',
    'selectQ',
    SearchEntry
);