import SearchService from '../search/SearchService';
import {OrmEvents} from '../OrmEvents';

const CHANGE_Q = 'CHANGE_Q';
const CLEAR_SEARCH='CLEAR_SEARCH';
const UPDATE_SEARCH_RESULTS='UPDATE_SEARCH_RESULTS';
const UPDATE_START_ROW='UPDATE_START_ROW';
const UPDATE_FILTER_PARAMS='UPDATE_FILTER_PARAMS';
const UPDATE_DA='UPDATE_DA';
const UPDATE_SORT='UPDATE_SORT';

const APIHOST = process.env.REACT_APP_APIHOST;

const defaultFilters={
    "org":"",
    "year":"ALL",
    "month":"0",
    "event":""
}

const getVtypeFilters=(vtype)=>{
    switch(vtype){
        case "pending":
            return ["org"];
        case "emergency":
            return ["event"];
        default:
            return ["org","year","month"];
    }
}

const isEmpty=(vtype,obj)=>{
    const props=getVtypeFilters(vtype);
    for(let i=0;i<props.length;i++){
        let defaultFilter = defaultFilters[props[i]];
        let val=obj[props[i]];
        if(val && val!==defaultFilter){
            return false;
        }
    }
    return true;
}

const searchUrl=`${APIHOST}/permits/search`;
        

export default {
    name:'search',
    getReducer(){
        const initialState = {
            q:'',
            da:true,
            start:0,
            sortField:null,
            sortDesc:null,
            filterParams:{},
            searchResult:{"total":0,"results":{"features":[]}},
            searchService: new SearchService({
                'url':searchUrl
            })
        }

        return (state = initialState, {type, payload}) => {
          switch(type){
            case CHANGE_Q:
            case CLEAR_SEARCH:
            case UPDATE_SEARCH_RESULTS:
            case UPDATE_START_ROW:
            case UPDATE_FILTER_PARAMS:
            case UPDATE_DA:
            case UPDATE_SORT:
              return({...state,...payload});
            default:
              return state;
          }
        }
    },

    doChangeQ:(payload)=> ({dispatch,store}) => {
        dispatch({"type":CHANGE_Q,"payload":payload});
        store.doSearch();
    },

    doUpdateStartRow:(start)=>({dispatch,store})=>{
        dispatch({"type":UPDATE_START_ROW,"payload":{"start":start}});
        store.doSearch();
    },

    doClearSearch:()=>({dispatch,store})=>{
        //dispatch({type:CLEAR_SEARCH,payload:{"q":"","filterParams":{},"start":0}});
        dispatch({type:CLEAR_SEARCH,payload:{"q":"","start":0}});
        store.doSearch();
    },

    doUpdateFilterParam:(key,val)=>({dispatch,store})=>{
        let fp=store.selectFilterParams();
        let filterParams={...fp,...{[key]:val}};
        dispatch({"type":UPDATE_FILTER_PARAMS,"payload":{"filterParams":filterParams}});
        store.doSearch();
    },

    doUpdateDa:(daSearch)=>({dispatch,store})=>{
        dispatch({type:UPDATE_DA,payload:{"da":daSearch}});
    },

    doUpdateSort:(sort)=>({dispatch,store})=>{
        dispatch({"type":UPDATE_SORT,"payload":{"start":0,"sortField":(sort.binding),"sortDesc":sort.sortDesc}});
        store.doSearch();
    },

    doClearSort:()=>({dispatch,store})=>{
        dispatch({"type":UPDATE_SORT,"payload":{"sortField":null,"sortDesc":null}});
        //store.doSearch();
    },


    /*
        Search Rules
         - Only show permits in map view when there is a query value and or a filter is not a default value
         - Always show permits in table view

    */
    doSearch:()=>({dispatch,store})=>{
        const filterParams=store.selectFilterParams();
        const q = store.selectQ();
        const map = store.selectMap();
        const mode=store.selectMode();
        const vtype=store.selectVtype();
        let disableClear=(isEmpty(vtype,filterParams) && q==="")?true:false;
        let geofenced=(map)?map.geoFence.isActive():false;
        OrmEvents.emit('onClearSelectedItem');
        //no search criteria
        if(q==="" && (mode==="map" || mode==="home") && isEmpty(vtype,filterParams) && !geofenced){
            dispatch({"type":UPDATE_SEARCH_RESULTS,"payload":{'searchResult':{"total":0,"results":{"features":[]}},"disableClear":true}})
        }
        else{
            let searchParams=store.selectSearchParams();
            // From Craig Hunter and Deanna.  
            // 1.  We should show duplicates for S408 Map
            // 2.  We should sort s408 permits by requestId
            if(searchParams.datype==="s408") { 
                searchParams.da = false;
                searchParams.sort = "requestId";
            };
            store.selectSearchService().search(searchParams,
            (searchresult)=>{
                dispatch( {"type":UPDATE_SEARCH_RESULTS,"payload":{'searchResult':searchresult,"disableClear":disableClear}});
            });
        }
    },

    selectQ:(store)=>{
        console.log("SELECTING Q");
        return store.search.q;
    },

    selectStart:(store)=>{
        console.log("SELECTING START");
        return store.search.start;
    },

    selectFilterParams:(store)=>{
        console.log("SELECTING FILTER PARAMS");
        return store.search.filterParams;
    },

    selectSearchParams:(store)=>{
        const s=store.search;
        return({
            'da':s.da, //essentually deduplicates search on Identifier.
            'datype':store.root.vtype,
            'filterParams':s.filterParams,
            'q':s.q,
            'vtype':store.root.vtype,
            'start':s.start,
            'map':store.map.map,
            'sort':s.sortField,
            'desc':s.sortDesc
        });
    },

    selectSearchService:(store)=>{
        return store.search.searchService;
    },

    selectSearchResult:(store)=>{
        console.log("SELECTING SEARCH RESULT");
        return store.search.searchResult;
    }
}