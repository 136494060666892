import React, { Component } from 'react';

export class HexbinLegend extends Component{

    
    getHexIcon=(color)=>{
        let icon='<svg style="width:24px;height:24px;transform:rotate(30deg);" viewBox="0 0 24 24">\
                    <path fill="'+color+'" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5Z" />\
                </svg>'
        return icon;
    }

    getArrowIcon=(color)=>{
        let icon='<svg style="width:24px;height:24px" viewBox="0 0 24 24">\
                    <path fill="'+color+'" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />\
                 </svg>'
        return icon;
    }

    getPaletteIcon=(color)=>{
        let icon='<svg style="width:20px;height:20px" viewBox="0 0 24 24">\
                    <path fill="'+color+'" d="M17.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,9A1.5,1.5 0 0,1 19,10.5A1.5,1.5 0 0,1 17.5,12M14.5,8A1.5,1.5 0 0,1 13,6.5A1.5,1.5 0 0,1 14.5,5A1.5,1.5 0 0,1 16,6.5A1.5,1.5 0 0,1 14.5,8M9.5,8A1.5,1.5 0 0,1 8,6.5A1.5,1.5 0 0,1 9.5,5A1.5,1.5 0 0,1 11,6.5A1.5,1.5 0 0,1 9.5,8M6.5,12A1.5,1.5 0 0,1 5,10.5A1.5,1.5 0 0,1 6.5,9A1.5,1.5 0 0,1 8,10.5A1.5,1.5 0 0,1 6.5,12M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A1.5,1.5 0 0,0 13.5,19.5C13.5,19.11 13.35,18.76 13.11,18.5C12.88,18.23 12.73,17.88 12.73,17.5A1.5,1.5 0 0,1 14.23,16H16A5,5 0 0,0 21,11C21,6.58 16.97,3 12,3Z" />\
                </svg>'
        return icon;
    }

    getArrowVal=(index,lastColor,color)=>{
        if(index===0){
            return (<div className="inline inlineCell">Most</div>);
        }
        else if(index===lastColor-1){
            return (<div className="inline inlineCell">Least</div>);
        }
        else{
            return (<div className="inline inlineCell" dangerouslySetInnerHTML={{'__html':this.getArrowIcon(color)}}/>);
        }
    }

    onChangePalette=(e)=>{
        let palette=e.currentTarget.id;
        if(this.props.hexbinLayer){
            this.props.hexbinLayer.changePalette(palette);
            this.forceUpdate();
        }
    }

    render(){
        let colorValues=[];
        if(this.props.hexbinLayer)
            colorValues=this.props.hexbinLayer.colorValues;
        return(
            <div className="HexbinLegend">
                <div className="legendTitle">Number of Actions</div>
                <div>
                    {colorValues.map((obj,index)=>{
                        return (
                            <div className="HexbinLegendItem" key={index}>
                                <div className="inline inlineCell" dangerouslySetInnerHTML={{'__html':this.getHexIcon(obj.color)}} key={index}/>
                                {this.getArrowVal(index,colorValues.length,obj.color)}
                            </div>
                        );
                    })}
                </div>
                <div className="hexbinPalette">
                    <div id="green" onClick={this.onChangePalette} className="inlineCell"  dangerouslySetInnerHTML={{'__html':this.getPaletteIcon("#54b567")}}/>
                    <div id="blue" onClick={this.onChangePalette} className="inlineCell"  dangerouslySetInnerHTML={{'__html':this.getPaletteIcon("#529DCC")}}/>
                    <div id="orange" onClick={this.onChangePalette} className="inlineCell"  dangerouslySetInnerHTML={{'__html':this.getPaletteIcon("#f67722")}}/>
                </div>
            </div>
        );
    }
}