import React,{Component} from 'react';

class SubAction extends Component{

    renderEsa(){
        return(
            <div>
                <table><tbody>
                    <tr><td style={{"width":"100px"}}><b>Begin Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.beginDate}</td></tr>
                    <tr><td><b>End Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.endDate}</td></tr>
                    <tr><td><b>Agency Notified:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.agency}</td></tr>
                    <tr><td><b>Consultation Type:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.consultationType}</td></tr>
                    <tr><td><b>Closure Method:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.closureMethod}</td></tr>
                </tbody></table>
            </div>
        );
    }

    renderEfh(){
        return(
            <div>
                <table><tbody>
                    <tr><td><b>Begin Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.beginDate}</td></tr>
                    <tr><td><b>End Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.endDate}</td></tr>
                    <tr><td><b>Agency Notified:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.agency}</td></tr>
                    <tr><td><b>Closure Method:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.closureMethod}</td></tr>
                </tbody></table>
            </div>
        );
    }

    renderWqc401(){
        return(
            <div>
                <table><tbody>
                    <tr><td><b>Begin Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.beginDate}</td></tr>
                    <tr><td><b>End Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.endDate}</td></tr>
                    <tr><td><b>Certifying Authority Notified:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.certifyingAuthorityNotified}</td></tr>
                    <tr><td><b>Closure Method:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.closureMethod}</td></tr>
                </tbody></table>
            </div>
        );
    }

    renderS106Nhpa(){
        return(
            <div>
                <table><tbody>
                    <tr><td><b>Begin Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.beginDate}</td></tr>
                    <tr><td><b>End Date:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.endDate}</td></tr>
                    <tr><td><b>Effects Determination:</b></td><td style={{"paddingLeft":"3px"}}>{this.props.result.effectsDetermination}</td></tr>
                </tbody></table>
            </div>
        );
    }

    render(){
        switch(this.props.type){
            case "esa":
                return(this.renderEsa());
                break;
            case "efh":
                return(this.renderEfh());
                break;
            case "s106Nhpa":
                return(this.renderS106Nhpa());
                break;
            case "wqc401":
                return(this.renderWqc401());
                break;
            default:
                return null;
        }
    }
}

export default SubAction;