import {OrmEvents} from '../OrmEvents.js';

const PUBLIC_URL = process.env.PUBLIC_URL;

var GeoFenceControl =  window.L.Control.extend({

	options: {
		position: 'topleft',
		shapeOptions: {
			stroke: true,
			color: '#3388ff',
			weight: 4,
			opacity: 0.5,
			fill: true,
			fillColor: null, //same as color by default
			fillOpacity: 0.2,
			showArea: true,
			clickable: true
		}
	},

	isControl:true,
		
	onAdd:function(map){
		this.map=map;
		let self=this;
		let container = window.L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
		container.style.backgroundColor = 'white';  
		container.style.backgroundSize = "30px 30px";
		this._showInactive(container);
		container.onclick=function(){
			if(self.fenceEnabled){
				self._startLatLng=undefined;
				self._unregisterDrawMapState();
				if(self._shape){
					self.map.removeLayer(self._shape);
					self._shape=undefined;
				}
				OrmEvents.emit('onRemoveMarker');	
				self.fenceEnabled=false;
				self._showInactive(this);
				OrmEvents.emit('doSearch', "Map GeoFence");	
			}
			else{
				self._registerDrawMapState();
				self._registerEvents();
				self.fenceEnabled=true;
				self._showActive(this);   
			}
		}
		this.container=container;
		return container;
	},

	_showActive:function(container){
		let title="When active, this will restrict search results to a user defined geographic boundary.\nThe default search considers location when searching, but will also return\nrelevant results from outside of the users map view.  This tool allows the user\nto draw a boundary and will exclude search results outside of the boundary.";
		container.innerHTML=`<div style="border-top: 1px solid #262b2b;background-image:url(${PUBLIC_URL}/searchbox.png);background-size: 30px 27px;background-color:#e3e3e3;"></div>`;
		container.title="Search within box tool (Currently Active):\n"+title;
	},

	_showInactive:function(container){
		let title="When active, this will restrict search results to a user defined geographic boundary.\nThe default search considers location when searching, but will also return\nrelevant results from outside of the users map view.  This tool allows the user\nto draw a boundary and will exclude search results outside of the boundary.";
		container.innerHTML=`<div style="border-top: 1px solid #262b2b;background-image:url(${PUBLIC_URL}/searchbox.png);background-size: 30px 27px;background-color:white;"></div>`; 
		container.title="Search within box tool (Currently Inactive):\n"+title;
	},

	onRemove: function(map) {
        if(this._shape){
			map.removeLayer(this._shape);
			this._shape=undefined;
		}
	},

	isActive:function(){
		return this.fenceEnabled;
	},

	getBounds:function(){
		return this._shape.getBounds();
	},

	getShape:function(){
		return this._shape;
	},

	reenable:function(shape){
		this.fenceEnabled=true;
		this._showActive(this.container); 
		this._shape=shape;
		this._addToMap();
		OrmEvents.emit('doSearch', "Map GeoFence");
	},

	_registerDrawMapState:function(){
		this.map.dragging.disable();
		this.map._container.style.cursor = 'crosshair';
	},

	_unregisterDrawMapState:function(){
		this.map.dragging.enable();
		this.map._container.style.cursor = 'default';
	},

	_registerEvents:function(){
		this.map.on('mousedown', this._onMouseDown, this);
		this.map.on('mousemove', this._onMouseMove, this);
	},

	_unregisterEvents:function(){
		this.map.off('mousedown', this._onMouseDown, this);
		this.map.off('mousemove', this._onMouseMove, this);
		window.L.DomEvent.off(document, 'mouseup', this._onMouseUp, this);
	},

	_onMouseDown:function(e){
		this._startLatLng = e.latlng;
		window.L.DomEvent.on(document, 'mouseup', this._onMouseUp, this)
			  .preventDefault(e.originalEvent);
	},

	_onMouseUp:function(e){
		this._unregisterEvents();
		this._unregisterDrawMapState();
		if (this._shape) {
			OrmEvents.emit('doSearch', "Map GeoFence");
		}
	},

	_onMouseMove:function(e){
		if(this._startLatLng)
			this._drawShape(e.latlng);
	},

	_drawShape:function(latlng){
		if (!this._shape) {
			this._shape = new window.L.Rectangle(new window.L.LatLngBounds(this._startLatLng, latlng), this.options.shapeOptions);
			this._addToMap();				
		} else {
			this._shape.setBounds(new window.L.LatLngBounds(this._startLatLng, latlng));
		}
	},

	_addToMap:function(){
		this.map.addLayer(this._shape);
		this._shape.bringToBack();
	}

	  
});

export default GeoFenceControl;