import React,{Component} from 'react'
import {SearchResult} from '../search/SearchResult.js';

export class ContactPanel extends Component{

    //@TODO: where is margin bottom coming from in table style???
    loadRegSite=()=>{
        window.open(this.props.contactData.web,'_blank');
    }

    render(){
        let c=this.props.contactData;
        return(
            <div className="ContactPanel">
                <div className="ContactInnerBox">
                    <b>USACE Regulatory Contact Information</b>
                    <div className="table" style={{"margin-bottom": "0px"}}>
                        <div className="tableRow">
                            <div className="tableAttrName"><em>Mailing:</em></div>
                            <div className="tableItem">{c.addr}</div>
                        </div>
                        <div className="tableRow">
                            <div className="tableArryName"><em>Phone:</em></div>
                            <div className="tableItem">{c.phone}</div>
                        </div>
                        <div className="tableRow">
                            <div className="tableAttrName"><em>Web:</em></div>
                            <div className="tableItem" stype={{"cursor":"pointer"}}onClick={this.loadRegSite}><a href="#"><div className='inline' dangerouslySetInnerHTML={{'__html':SearchResult.webIcon}}/>District Regulatory Web Site</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}