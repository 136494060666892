import React,{Component} from 'react';
//import SubAction from '../permit-actions/SubAction';
import SubActions from '../permit-actions/SubActions';

export class OrmPermit extends Component{

    constructor(props){
        super(props);
        this.baseFields={
            permitType:"Permit Type"
            //applicant:"Applicant"
        }
    }

    renderSubActions(){
        const saTypes=["efh","esa","s106Nhpa","wqc401"];
        
        if(this.props.result.properties.subactions){
            return(
                <div className="PermitSubActionResults">
                    {saTypes.map((subActionType)=>{
                        let subactions = this.props.result.properties.subactions[subActionType];
                        if(subactions){
                            return(
                                <SubActions
                                    subactions={subactions}
                                    subActionType={subActionType} 
                                />
                            )
                        }
                    })}
                </div>
            );
        }
        else{
            return null;
        }
    }

    render(){
        let self=this;
        return (
            <div className="SearchResult">
                <div className="SearchResultsTable">
                {Object.keys(this.baseFields).map(function(key){
                    return(
                        <div className="SearchResultsTableRow">
                            <div className="SearchResultsTableHeading"><b>{self.baseFields[key]}:</b></div>
                            <div className="SearchResultsTableValue"> {self.props.result.properties[key]}</div>
                        </div>
                    )
                })}
                {Object.keys(this.fields).map(function(key){
                    return(
                        <div className="SearchResultsTableRow">
                            <div className="SearchResultsTableHeading"><b>{self.fields[key]}:</b></div>
                            <div className="SearchResultsTableValue"> {self.props.result.properties[key]}</div>
                        </div>
                    )
                })}
                </div>
                {(this.props.result.properties.subactions)?(
                    <div className="SearchResultsTableHeading"><b><u>Subactions:</u></b></div>
                ):null}
                <div className="PermitSubActionResultsContainer">
                    {this.renderSubActions()}
                </div>
            </div>
        );
    }

}