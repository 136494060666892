import {OrmEvents} from '../OrmEvents.js';

class SearchLayer{

    constructor(map,geojson){
        this.map=map;

        this.layer=window.L.geoJson(geojson,{
            onEachFeature:this.onEachFeature
        }).bindTooltip((layer)=>{
            return this.toolTipBuilder(layer);
        }).addTo(map);
    }

    toolTipBuilder=(layer)=>{
        var props=layer.feature.properties;
        let name=(props.projectName===undefined)?props.requestName:props.projectName;
        var html=props.identifier+'<br>'+ name + '<br>';
        return html;
    }

    onEachFeature=(feature,layer)=>{
        layer.on({
            mouseover: this.selectPermit,
            //mouseout: this.resetSelectedPanelItem,
        });
    }

    selectPermit=(e)=>{
        OrmEvents.emit('onMapItemHover', e.target.feature);
        OrmEvents.emit('highlightAndFlyToPermit',{'permit':e.target.feature,'forceFly':false}); 
    }
    
    clear=()=>{
        if(this.layer)
            this.layer.remove();
    }
   

}

export default SearchLayer;