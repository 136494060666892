import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';
import {orgSelect,yearSelect,monthSelect,eventSelect,eaActionTypeTable,stuff} from '../table/TableUtils.js';
import _ld  from 'lodash';

class SearchFilters extends Component{

    constructor(props){
        super(props);
    }

    filterDiv = React.createRef();

    onChange=(e)=>{
        this.props.doUpdateFilterParam(e.target.id,e.target.value);
    }

    renderFilterForm(){
      switch(this.props.vtype){
        case "pending":
        return (<div style={{"display":"table"}}>
                    {orgSelect(this.onChange,this.props.filterParams)}
                </div>);
        case "emergency":
        return (<div style={{"display":"table"}}>
                    {eventSelect(this.onChange,this.props.filterParams,this.props.disasterEvents,125)}
                </div>);
        case "nepa_ea":
        return (<div style={{"display":"table"}}>
                     {orgSelect(this.onChange,this.props.filterParams)}
                </div>);
        case "nepa_eis":
            return (<div style={{"display":"table"}}>
                    {orgSelect(this.onChange,this.props.filterParams)}
                    {yearSelect(this.onChange,this.props.filterParams)}
                    {monthSelect(this.onChange,this.props.filterParams)}
                </div>);
        default: //all,issued,wrda,deepwater horizons, and ajd all use the same query form.
          return (<div style={{"display":"table"}}>
                    {orgSelect(this.onChange,this.props.filterParams)}
                    {yearSelect(this.onChange,this.props.filterParams)}
                    {monthSelect(this.onChange,this.props.filterParams)}
                  </div>);
      }
    }

    render(){
        //let queryParams=this.queryParams;
        return(
            <div ref={this.filterDiv} style={{"display":"inline-block","fontSize":"12px"}} className="TableQueryFormVertical">
                {this.renderFilterForm()}
            </div>
        );
    }
}

export default connect(
    'doUpdateFilterParam',
    'selectFilterParams',
    'selectDisasterEvents',
    'selectVtype',
    SearchFilters
);