import React,{Component} from 'react';
import SearchPanel from '../search/SearchPanel';
import Map from '../gis/Map';

class GeospatialView extends Component{

    constructor(props){
        super(props);
        /*
        let corner1 = window.L.latLng(10, -180);
        let corner2 = window.L.latLng(80, -10);
        let bounds = window.L.latLngBounds(corner1, corner2);
        this.state={
          "latLngBounds":bounds,
          "map":null
        }
        */
    }

    render(){
        return(
            <div className="AppBodyContent">
                <div className="AppBodyContentResults">
                    <div style={{'height':'40px'}}>
                    </div>
                    <SearchPanel
                        //map={this.state.map}
                        //vtype={this.props.vtype}
                        //selectedIdentifier={this.props.selectedIdentifier}
                        //setSelectedIdentifier={this.props.setSelectedIdentifier}
                        //searchResult={this.props.searchResult}
                    />
                </div>
                <div className="AppBodyContentMap">
                    <Map 
                        //setLatLngBounds={this.props.setLatLngBounds}
                        //setMap={this.props.setMap}
                        //vtype={this.props.vtype}
                        //searchResult={this.props.searchResult}
                    />
                </div>
            </div>
        );
    }
}

export default GeospatialView;