import React, { Component } from 'react';
import { connect } from 'redux-bundler-react';
import {OrmEvents} from '../OrmEvents.js';
import {SearchResult} from './SearchResult.js';
import SearchResults from './SearchResults.js';
import PermitActions from './permit-actions/PermitActions.js';
import SearchEntry from './SearchEntry';

import QueryBuilder from './QueryBuilder';
import CSV from '../table/Csv.js';

class SearchPanel extends Component{

    constructor(props){
        super(props);
        this.state={filterDisplay:false,showPermitActions:false};
        this.flyToInitialPermit=false;
        
        this.mapItemHoverEvent = OrmEvents.addListener('onMapItemHover', (e) => {
            this.props.doChangeSelectedIdentifier(e.properties.identifier);
        });

        OrmEvents.addListener('showPermitActions', (feature) => {
            this.setState({"showPermitActions":true});
            this.props.doChangeSelectedIdentifier(feature.properties.identifier);
        });

        OrmEvents.addListener('closePermitActions', (feature) => {
            this.setState({"showPermitActions":false});
        });

        OrmEvents.addListener('searchPanelHide', (e) => {
            this.setState({"showPermitActions":e});
        });

    }

    componentDidMount=()=>{
        if(this.props.selectedIdentifier!==null){
            this.setState({"q":this.props.selectedIdentifier});
            this.flyToInitialPermit=true;
        }
    }
    
    //highlights the selected marker if it exists in a new set of search results
    highlightSelected=(results,identifier)=>{
        let features=results.features;
        for(let i=0;i<features.length;i++){
            if(identifier===features[i].properties.identifier){
                OrmEvents.emit('onNewSearchResult', features[i]);
            }
        }
    }

    render(){
        let deDup={};
        let vis=(this.state.showPermitActions)?"none":"inherit";
        let showFiltersIcon="glyphicon "+((this.state.filterDisplay)?"glyphicon-triangle-top":"glyphicon-triangle-bottom");
        return(
            <div className="SearchPanel">
                <div style={{"display":vis}}>                        
                    <SearchResults
                        //results={this.props.searchResult.results}
                        //selectedIdentifier={this.props.selectedIdentifier}
                        //selectPermit={this.selectPermit}
                    />
                </div>
                {this.state.showPermitActions ? (
                    <PermitActions
                        //selectedIdentifier={this.state.selectedPermitActionsIdentifier}
                    />
                ) : (
                    null
                )}
            </div>
        );
    }
}

export default connect (
    'doChangeSelectedIdentifier',
    SearchPanel
);