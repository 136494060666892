import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';

class SearchPagenator extends Component {

    maxDisplayItems=25;

    pagenateForward=()=>{
        const total=this.props.searchResult.total;
        let val=this.props.start+this.maxDisplayItems;
        val=(val>total)?total:val;
        if(val!==total)
            this.props.doUpdateStartRow(val);
    }

    pagenateBackward=()=>{
        let val=this.props.start-this.maxDisplayItems;
        val=(val<0)?0:val;
        this.props.doUpdateStartRow(val);
    }



    renderPrevious(){
        const count=this.props.searchResult.results.features.length;
        if(this.props.start-count>=0){
            return(
                <div style={{"marginRight":"5px","cursor":"pointer"}} onClick={this.pagenateBackward} className="glyphicon glyphicon glyphicon-chevron-left" title="Previous Results">
                </div>
            )
        }
        else
            return null;
    }

    renderNext(){
        const total=this.props.searchResult.total;
        const count=this.props.searchResult.results.features.length;
        if(total>this.props.start+count){
            return(
                <div style={{"marginLeft":"5px","cursor":"pointer"}} onClick={this.pagenateForward} className="glyphicon glyphicon glyphicon-chevron-right" title="Next Results">
                </div>
            )
        }
        else
            return null;
    }

    render(){
        const total=this.props.searchResult.total;
        const count=this.props.searchResult.results.features.length;
        const startNum=this.props.start+1;
        const maxNum=this.props.start+count;
        const endNum=(total<maxNum)?total:maxNum;
        return(
            <div className="SearchPagenation">
                {total!==0?(
                    <center>
                        <table style={{"fontSize":"13px","color": "#868686"}}><tr>
                            <td>{this.renderPrevious()}</td>
                            <td>Displaying {startNum} to {endNum} of {total} matching actions</td>
                            <td>{this.renderNext()}</td>
                        </tr></table>
                    </center>
                ):null}
                
            </div>
        );
    }
}

export default connect(
    'selectSearchResult',
    'selectStart',
    'doUpdateStartRow',
    SearchPagenator
);