import React,{Component} from 'react';

export class AjdPermit extends Component{
    constructor(props){
        super(props);
        this.fields={
            district:"District",
            daNumber:"DA Number",
            projectName:"Project Name",
            displayDate:"Finalized Date",
            jdType: "AJD Type"
        }
    }
    
    getDocument=()=>{
        window.open(this.props.result.properties.ajdUrl,"_blank");
    }

    render(){
        let self=this;
        return (
            <div className="SearchResult">
                <div className="SearchResultsTable">
                {Object.keys(this.fields).map(function(key){
                    return(
                        <div className="SearchResultsTableRow">
                            <div className="SearchResultsTableHeading"><b>{self.fields[key]}:</b></div>
                            <div className="SearchResultsTableValue"> {self.props.result.properties[key]}</div>
                        </div>
                    )
                })}
                <div className="SearchResultsTableRow">
                    <div className="SearchResultsTableHeading"><b>Link to AJD Form:</b></div>
                    <div className="SearchResultsTableValue" onClick={self.getDocument}><a href="#">Document Link</a></div>
                </div> 
                </div>
            </div>
        );
    }
}