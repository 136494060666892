import {createCustomAlert } from './CustomAlertBox';

const getVtype=function(val){
    switch(val){
        case "All Content":
            return "all";
        case "Final IP":
            return "issued";
        case "Pending IP":
            return "pending";
        case "Emergency":
            return "emergency";
        case "WRDA":
            return "wrda";
        case "DWHS":
            return "dwhs";
        case "AJD":
            return "jds";
        case "Section 408":
            return "s408"
        case "NEPA EA":
            return "nepa_ea"
        case "NEPA EIS":
            return "nepa_eis"
        // case "NEPA":
        //     return "nepa"    
        default:
            return null;
    }
}

const getVName=function(val){
    switch(val){
        case "all":
            return "All Content";
        case "issued":
            return "Final IP";
        case "pending":
            return "Pending IP";
        case "emergency":
            return "Emergency";
        case "wrda":
            return "WRDA";
        case "dwhs":
            return "DWHS";
        case "jds":
            return "AJD";
        case "s408":
            return "Section 408"
        case "nepa_ea":
            return "NEPA EA";
        case "nepa_eis":
            return "NEPA EIS";
        // case "nepa":
        //     return "NEPA";
        default:
            return null;
    }
}

const getVTitle=function(val){
    switch(val){
        case "all":
        case "All Content":
            return "All Content";
        case "issued":
        case "Final IP":
            return "Final Individual Permits";
        case "Pending IP":
        case "pending":
            return "Pending Individual Permits";
        case "emergency":
        case "Emergency":
            return "Emergency Permits";
        case "WRDA":
        case "wrda":
            return "Water Resources Development Act Projects";
        case "DWHS":
        case "dwhs":
            return "Deepwater Horizon Projects";
        case "AJD":
        case "jds":
            return "Approved Jurisdictional Determinations";
        case "Section 408":
        case "s408":
            return "Section 408 Permissions"
        case "NEPA EA":
        case "nepa_ea":
            return "NEPA Environmental Assessments";
        case "NEPA EIS":
        case "nepa_eis":
            return "NEPA Environmental Impact Statements";
        default:
            return null;
    }
}

const getUrlParameter=function(name){
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const initialView=function(){
    return({
        "vtype":getUrlParameter("type"),
        "mode":getUrlParameter("mode"),
        "q":getUrlParameter("q"),
        "org":getUrlParameter("org"),
        "year":getUrlParameter("year"),
    });
}

const dateToYMD=function(date){
    try {
        let wkDate = date;
        wkDate = wkDate.replace('["', '');
        wkDate = wkDate.replace('"]', '');
        let wkDate2 = Date.parse(wkDate);
        let wkDate3 = new Date(wkDate2);
        let d = wkDate3.getDate();
        let m = wkDate3.getMonth() + 1; //Month from 0 to 11
        let y = wkDate3.getFullYear();
        
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }
    catch {
        return "Not Available";
    }
};

const copyTextToClipboard=async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      createCustomAlert(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
};

const createUrlFromState=(url="", props={})=>{
    let wkUrl = "";
    let params = {};

    if(url && url !== "") { wkUrl = url.split("?")[0]; }
    if(props.vtype) { params.type = props.vtype; }
    if(props.mode) { params.mode = props.mode; }
    if(props.q) { params.q = props.q; }
    if(props.filterParams && props.filterParams.org) { params.org = props.filterParams.org; }
    if(props.filterParams && props.filterParams.year) { params.year = props.filterParams.year; }

    for (const [key, value] of Object.entries(params)) {
        if(wkUrl.includes("?")) {
            wkUrl = wkUrl + "&" + key.toString() + "=" + value;
        } else {
            wkUrl = wkUrl + "?" + key.toString() + "=" + value;
        }
    }

    return wkUrl;
  }




export {getVtype,getVName,getVTitle,initialView,getUrlParameter,dateToYMD,copyTextToClipboard,createUrlFromState};
