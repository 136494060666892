import React, { Component } from 'react';
import {OrmEvents} from '../OrmEvents.js';
import {getContactData} from '../contact/ContactData.js';
import {ContactPanel} from '../contact/ContactPanel.js';

export class SearchResult extends Component{

    constructor(props){
        super(props);
        this.state={"showContact":false,"contactData":null};
        //this.highlight=false;
    }

    selectPermit=(e)=>{//@TODO change event name 
        OrmEvents.emit('highlightAndFlyToPermit', {'permit':this.props.result,'forceFly':false});
    }

    highlightPermit=(e)=>{
        OrmEvents.emit('onNewSearchResult',this.props.result);
    }

    onPaClick=(e)=>{ 
        OrmEvents.emit('showPermitActions', this.props.result);
    }

    onContactInfoClick=(e)=>{
        if(this.state.showContact===true){
            this.setState({"showContact":false,"contactData":null});
        }
        else{
            let contact=getContactData(this.props.result.properties.org);
            if(contact){
                this.setState({"showContact":true,"contactData":contact});
            }
        }
    }

    getAjdDocument=()=>{
        window.open(this.props.result.properties.ajdUrl,"_blank");
    }

    renderS408Action(){
        let p=this.props;
        let id="sr"+this.props.index;
        
        let cn="SearchResult";
        if(this.props.selectedIdentifier===this.props.result.properties.identifier && this.props.getHighlight()===true){
            cn="HighlightedSearchResult";
        }
        //let numPermitText=this.props.result.properties.count+" Permit"+((this.props.result.properties.count>1)?"s":"");
        return(
            <div id={id} className={cn} onClick={this.selectPermit} onMouseOver={this.highlightPermit}>      
                <b>S408 Number: {this.props.result.properties.identifier}</b>
                <div className="SearchResultsTable">
                    <div className="SearchResultsTableRow">
                        <div className="SearchResultsTableHeadingNarrow"><b><em>Request Name:</em></b></div>
                        <div className="SearchResultsTableValue">{this.props.result.properties.requestName}</div>
                    </div>
                    <div className="SearchResultsTableRow">
                        <div className="SearchResultsTableHeadingNarrow"><b><em>Status</em></b></div>
                        <div className="SearchResultsTableValue">{this.props.result.properties.status}</div>
                    </div>
                </div>
                <div style={{"paddingLeft":"10px","paddingTop":"5px"}}>
                    <div title="Show Permit Details" className='inline' style={{"cursor":"pointer"}} onClick={this.onPaClick} dangerouslySetInnerHTML={{'__html':SearchResult.dbSearchIcon}}/>
                    {(this.props.result.properties.vtype==='jds')?
                        (<div title="Download Permit Documents" className='inline' style={{'padding':'0px'}}><div data-tip="React-tooltip" data-for='document' className='inline' style={{"cursor":"pointer"}} onClick={this.getAjdDocument}dangerouslySetInnerHTML={{'__html':SearchResult.permitTypeStandard}}/>
                        </div>):null                    
                    }
                    <div title="Show USACE Contact Information" className='inline' style={{"cursor":"pointer"}} onClick={this.onContactInfoClick} dangerouslySetInnerHTML={{'__html':SearchResult.applicantIcon}}/>
                </div>
                {(this.state.showContact===true)?(
                    <ContactPanel
                        contactData={this.state.contactData}
                    />
                 ):null}
            </div>
        );
    }

    renderPermitAction(){
        let p=this.props;
        let id="sr"+this.props.index;
        
        let cn="SearchResult";
        if(this.props.selectedIdentifier===this.props.result.properties.identifier && this.props.getHighlight()===true){
            cn="HighlightedSearchResult";
        }
        //let numPermitText=this.props.result.properties.count+" Permit"+((this.props.result.properties.count>1)?"s":"");
        return(
            <div id={id} className={cn} onClick={this.selectPermit} onMouseOver={this.highlightPermit}>      
                <b>DA Number: {this.props.result.properties.daNumber}</b>
                <div className="SearchResultsTable">
                    <div className="SearchResultsTableRow">
                        <div className="SearchResultsTableHeadingNarrow"><b><em>District:</em></b></div>
                        <div className="SearchResultsTableValue">{this.props.result.properties.district} ({this.props.result.properties.org})</div>
                    </div>
                    
                    
                    <div className="SearchResultsTableRow">
                        <div className="SearchResultsTableHeadingNarrow"><b><em>Project Name:</em></b></div>
                        <div className="SearchResultsTableValue">{this.props.result.properties.projectName}</div>
                    </div>
                    {(this.props.result.properties.vtype!=='jds')?(
                    <div className="SearchResultsTableRow">
                        <div className="SearchResultsTableHeadingNarrow"><b><em>Applicant:</em></b></div>
                        <div className="SearchResultsTableValue">{this.props.result.properties.applicant}</div>
                    </div>
                    ):null}
                </div>
                <div style={{"paddingLeft":"10px","paddingTop":"5px"}}>
                    <div title="Show Permit Details" className='inline' style={{"cursor":"pointer"}} onClick={this.onPaClick} dangerouslySetInnerHTML={{'__html':SearchResult.dbSearchIcon}}/>
                    {(this.props.result.properties.vtype==='jds')?
                        (<div title="Download Permit Documents" className='inline' style={{'padding':'0px'}}><div data-tip="React-tooltip" data-for='document' className='inline' style={{"cursor":"pointer"}} onClick={this.getAjdDocument}dangerouslySetInnerHTML={{'__html':SearchResult.permitTypeStandard}}/>
                        </div>):null                    
                    }
                    <div title="Show USACE Contact Information" className='inline' style={{"cursor":"pointer"}} onClick={this.onContactInfoClick} dangerouslySetInnerHTML={{'__html':SearchResult.applicantIcon}}/>
                </div>
                {(this.state.showContact===true)?(
                    <ContactPanel
                        contactData={this.state.contactData}
                    />
                 ):null}
            </div>
        );
    }

    render(){
        return(
            <div>
                {(this.props.result.properties.vtype==='s408')?
                  this.renderS408Action()
                  :
                  this.renderPermitAction()
                }
            
            </div>
        );
    }

    static districtIcon='<svg style="width:16px;height:16px;position:relative;top:2px;" viewBox="0 0 24 24">\
        <path fill="#000000" d="M9,19V13H11L13,13H15V19H18V10.91L12,4.91L6,10.91V19H9M12,2.09L21.91,12H20V21H13V15H11V21H4V12H2.09L12,2.09Z" />\
    </svg>';
    static permitTypeLetter='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
        <path fill="#555555" d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />\
    </svg>';

    static permitTypeStandard='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
                <path fill="#555555" d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />\
            </svg>';
        
    static permitTypePending='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
            <path fill="#555555" d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" />\
        </svg>';

    static projectIcon='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
        <path fill="#555555" d="M11,13.5V21.5H3V13.5H11M12,2L17.5,11H6.5L12,2M17.5,13C20,13 22,15 22,17.5C22,20 20,22 17.5,22C15,22 13,20 13,17.5C13,15 15,13 17.5,13Z" />\
    </svg>';

    static applicantIcon='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
        <path fill="#555555" d="M12,2A2,2 0 0,1 14,4A2,2 0 0,1 12,6A2,2 0 0,1 10,4A2,2 0 0,1 12,2M10.5,7H13.5A2,2 0 0,1 15.5,9V14.5H14V22H10V14.5H8.5V9A2,2 0 0,1 10.5,7Z" />\
    </svg>';

    static dbSearchIcon='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
        <path fill="#555555" d="M10,1C5.58,1 2,2.79 2,5C2,7.06 5.13,8.74 9.15,8.96C10.45,7.7 12.19,7 14,7C14.8,7 15.59,7.14 16.34,7.41C17.37,6.74 18,5.91 18,5C18,2.79 14.42,1 10,1M2,7V10C2,11.68 4.08,13.11 7,13.71C7.06,12.7 7.32,11.72 7.77,10.82C4.44,10.34 2,8.82 2,7M13.93,8.94C12.75,8.95 11.53,9.4 10.46,10.46C6.21,14.71 11.71,21.5 16.75,18.17L21.29,22.71L22.71,21.29L18.17,16.75C20.66,12.97 17.47,8.93 13.93,8.94M13.9,11C15.47,10.95 17,12.16 17,14A3,3 0 0,1 14,17C11.33,17 10,13.77 11.88,11.88C12.47,11.29 13.19,11 13.9,11M2,12V15C2,17.05 5.09,18.72 9.06,18.95C8.17,18.07 7.54,16.95 7.22,15.74C4.18,15.17 2,13.72 2,12Z" /> \
    </svg>';

    static backArrowIcon='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
    <path fill="#000000" d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M7,12L12,17V14H16V10H12V7L7,12Z" />\
    </svg>';

    static downloadIcon='<svg style="width:24px;height:24px;position:relative;top:2px" viewBox="0 0 24 24">\
        <title>Download Search Results</title>\
        <path fill="#000000" d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />\
    </svg>';

    static webIcon='<svg style="width:16px;height:16px;position:relative;top:2px" viewBox="0 0 24 24">\
                        <path fill="#555555" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />\
                    </svg>';

    static earthBoxIcon='<svg style="width:24px;height:24px" viewBox="0 0 24 24">\
        <path fill="#000000" d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H5M15.78,5H19V17.18C18.74,16.38 17.69,15.79 16.8,15.79H15.8V12.79A1,1 0 0,0 14.8,11.79H8.8V9.79H10.8A1,1 0 0,0 11.8,8.79V6.79H13.8C14.83,6.79 15.67,6 15.78,5M5,10.29L9.8,14.79V15.79C9.8,16.9 10.7,17.79 11.8,17.79V19H5V10.29Z" /> \
    </svg>';


   
}