import {OrmPermit} from './OrmPermit.js';

export class EmergencyPermit extends OrmPermit{

    constructor(props){
        super(props);
        this.fields={
            beginDate:"Begin Date",
            actionTaken:"Action Taken",
            displayDate:"Date Issued/Denied",
            event:"Event",
            state:"State",
            county:"County",
            pnn:"PNN",
            status:"Status"
        }
    }
}