
const UPDATE_GEOFENCE = 'UPDATE_GEOFENCE';
const SAVE_MAPBOUNDS='SAVE_MAPBOUNDS';
const SAVE_FENCEBOUNDS='SAVE_FENCEBOUNDS';
const UPDATE_MAP='UPDATE_MAP';

let initialBounds={"center":[35, -100],"zoom":5}

export default {
    name:'map',
    getReducer(){
        const initialState = {
            geoFence:{"isActive":()=>false,isControl:false},
            map:null,
            mapBounds:initialBounds,
            fenceBounds:null
        }

        return (state = initialState, {type, payload}) => {
          switch(type){
            case UPDATE_GEOFENCE:
            case SAVE_MAPBOUNDS:
            case SAVE_FENCEBOUNDS:
            case UPDATE_MAP:
              return({...state,...payload});
            default:
              return state;
          }
        }
    },

    doSaveMapBounds:(bounds)=>({dispatch,store})=>{
        dispatch({type:SAVE_MAPBOUNDS,payload:{"mapBounds":bounds}});
    },

    doSaveFenceBounds:(bounds)=>({dispatch,store})=>{
        dispatch({type:SAVE_FENCEBOUNDS,payload:{"fenceBounds":bounds}});
    },

    doUpdateMap:(map)=>({dispatch,store})=>{
        dispatch({type:UPDATE_MAP,payload:{"map":map}})
    },

    selectMapBounds:(store)=>{
        console.log("SELECTING MAPBOUNDS");
        return store.map.mapBounds;
    },

    selectFenceBounds:(store)=>{
        console.log("SELECTING FENCEBOUNDS");
        return store.map.fenceBounds;
    },

    selectMap:(store)=>{
        console.log("SELECTING MAP");
        return store.map.map;
    }
}