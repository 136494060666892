import React, { Component } from 'react';
import { connect } from 'redux-bundler-react';
import SearchEntry from './search/SearchEntry';
import GeospatialView from './views/GeospatialView';
import TableView from './table/TableView';
import SearchFilters from './search/SearchFilters';
import SearchPagenator from './search/SearchPagenator';
import SearchLayer from './search/SearchLayer';
// import Feedback from './Feedback';
import {getVtype,getVName,getVTitle,initialView,copyTextToClipboard,createUrlFromState} from './VTypeUtils';
// import {createCustomAlert } from './CustomAlertBox';
import SearchService from './search/SearchService';
import {zoomToFeatures,getEnvelope} from './gis/GisUtils.js';
import {OrmEvents} from './OrmEvents.js';
import Dropdown, {
    DropdownToggle,
    DropdownMenu,
    DropdownMenuWrapper,
    MenuItem,
    DropdownButton
  } from '@trendmicro/react-dropdown';
import './css/bootstrap/paper.bootstrap.min.css';
import './css/wijmo.min.css';
import '../node_modules/@corpsmap/corpsmap_argon/dist/corpsmap-light.css';
import './Root.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

const PUBLIC_URL = process.env.PUBLIC_URL;
const APIHOST = process.env.REACT_APP_APIHOST;
const NEPA_URL = process.env.REACT_APP_NEPA_RESOURCE_URL;
const DISTRICT_OFFICE_FINDER_URL = process.env.REACT_APP_DISTRICT_OFFICE_FINDER_URL;
const SHOW_ORM_LAST_UPDATE_DATE = process.env.REACT_APP_SHOW_ORM_LAST_UPDATE_DATE;
const SHOW_ORM_INDEX_JOB_DATE = process.env.REACT_APP_SHOW_ORM_INDEX_JOB_DATE;
const REGULATORY_REQUEST_SYSTEM = process.env.REACT_APP_REGULATORY_REQUEST_SYSTEM;

class Root extends Component{

    constructor(props){
        super(props);

        this.state={
            //"filterParams":{},
        }

        this.doSearchEvent = OrmEvents.addListener('doSearch', (e) => {
            console.log("New search requested by: "+e);
            this.props.doChangeSelectedIdentifier(null);
            this.props.doSearch();
        });
        window.onresize = this.resize;
    }

    componentDidMount(){
        this.setState({'navMenu':(window.innerWidth<1300)});
        this.searchService = new SearchService({
            "url":this.geodataRootUrl
        });
        this.props.doGetNaturalDisasterEventList();
        this.props.doGetOrmIndexJobDate();
        this.props.doGetOrmAsOfDate();
        // this.props.doSetInitialView();
        // this.props.doUpdateFilterParam("org","NWP");
    }

    resize=(e)=>{
        this.setState({'navMenu':(e.target.innerWidth<1300)});
    }  
    
    resetFilterParams(){
        this.setState({"filterParams":{}});
    }

    setFilterParams=(filterParams)=>{
        this.setState({"filterParams":filterParams});
    }

    setFilterParam=(key,value)=>{
        let q = {...this.state.filterParams};
        q[key]=value;
        this.setState({"filterParams":q,"start":0},this.doSearch);
    }

    
    isEmpty=(obj)=>{
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    
    onAgree=()=>{
        this.props.doAgree();
    }
    
    onHome=()=>{
        this.props.doChangeMode("home");
    }

    onNavbarClick=(e)=>{
        let val=e.target.innerText;
        if(this.props.mode==='home'){
            this.props.doChangeModeState({"mode":"map","vtype":getVtype(val)});
        }
        else{
          this.props.doChangeModeState({"vtype":getVtype(val),"start":0});
          this.props.doSearch();
        }
    }

    onNavMenuClick=(e)=>{
        const val=e;
        if(this.props.mode==='home'){
            this.props.doChangeModeState({"mode":"map","vtype":val});
        }
        else{
            this.props.doChangeModeState({"vtype":val,"start":0});
            this.props.doSearch();
        }
    }

    onViewToggleClick=(e)=>{
        let vt=(this.props.mode==='')?'all':this.props.vtype;
        let val=e.target.attributes.getNamedItem("toggleval").value;
        let toggle=(val.trim()==="Table View")?'table':'map';
        if(toggle==='table' && (vt==='all' || vt==='')){
            this.props.doChangeModeState({"vtype":'issued',"mode":toggle});
        }
        else if(toggle==='map' && vt===''){
            this.props.doChangeModeState({"vtype":'all',"mode":toggle});
        }
        else{
            this.props.doChangeModeState({"vtype":vt,"mode":toggle});
        }
        this.props.doSearch();
    }

    getVClass(val){
        if(this.props.mode==="home"){
          return null;
        }
        else{
          if(val===this.props.vtype){
            return "selectedCat";
          }
          else{
            return "";
          }
        }
    }

    ormIndexJobDate() {
      return this.props.ormIndexJobDate;
    }

    getOrmAsOfDate() {
      return this.props.getOrmAsOfDate;
    }
    
    renderNavbar(){
        
        return(
          <nav style={{'overflow':''}} className="navbar navbar-inverse navbar-fixed-top">
            <div className="container-fluid">
            <div style={{"cursor":"pointer"}} onClick={this.onHome}>
              <a className="navbar-brand" style={{"width":"350px"}} href="#"><img src={`${PUBLIC_URL}/usace.png`} height="63" /></a>
              <div className="navLogo"><img src={`${PUBLIC_URL}/usace2.png`}/>  US Army Corps of Engineers<div className="tradeMark">&reg;</div></div>
            </div>
            
            <ul className="nav nav-pills">
              {this.props.mode!=='table'?(
                <li title="All IPs, Emergency, WRDA, and DWHS permits, and AJDs"><a href="#" className={this.getVClass('all')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>All Content</a></li>
              )
              :null}
              <li title="Final Individual Permits"><a href="#" className={this.getVClass('issued')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>Final IP</a></li>
              <li title="Pending Individual Permits"><a href="#" className={this.getVClass('pending')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>Pending IP</a></li>
              <li title="List of NEPA EA Reviews"><a href="#" className={this.getVClass('nepa_ea')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>NEPA EA</a></li>
              <li title="List of NEPA EIS Reviews"><a href="#" className={this.getVClass('nepa_eis')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>NEPA EIS</a></li>
              <li title="Emergency Permits"><a href="#" className={this.getVClass('emergency')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>Emergency</a></li>
              <li title="Water Resources Development Act"><a href="#" className={this.getVClass('wrda')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>WRDA</a></li>
              <li title="Deepwater Horizon Settlements"><a href="#" className={this.getVClass('dwhs')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>DWHS</a></li>
              <li title="Approved Jurisdictional Determinations"><a href="#" className={this.getVClass('jds')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>AJD</a></li>
              <li title="Section 408"><a href="#" className={this.getVClass('s408')} onClick={this.onNavbarClick} style={{"color": "#CCC"}}>Section 408</a></li>
              {/* {this.renderNepaMenu()} */}

            </ul>  
            </div>
            
          </nav>
        );
    }

    renderNavbarMenu(){
        return(
          <div>
            <nav style={{'overflow':'hidden'}} className="navbar navbar-inverse navbar-fixed-top">
              <div className="container-fluid">
                <div style={{"cursor":"pointer"}} onClick={this.onHome}>
                  <a className="navbar-brand" style={{"width":"350px"}} href="#"><img src={`${PUBLIC_URL}/usace.png`} height="63" /></a>
                  <div className="navLogo"><img src={`${PUBLIC_URL}/usace2.png`}/>  US Army Corps of Engineers</div>
                </div>
              </div>
            </nav>
            <div className="DropDownMenu">
            <Dropdown style={{'background-color':'transparent'}}>
                  <Dropdown.Toggle
                      btnStyle="flat"
                      style={{
                        'backgroundColor':'#000000',
                        'backgroundImage':'none',
                        'fontFamily': "'Montserrat', helvetica, sans-serif",
                        'font-size': '14px',
                        'color': '#CFCFCF',
                        'border-width': '0px',
                        'height':'45px'
                      
                      }}
                  >
                    {(this.props.vtype==='')?'':(getVName(this.props.vtype))}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <MenuItem header>Select an Action Type</MenuItem>
                        {this.props.mode!=='table'?(
                          <MenuItem eventKey={'all'} onSelect={this.onNavMenuClick} active={this.props.vtype==='all'}>All Content</MenuItem>
                        ):null}
                        <MenuItem eventKey={'issued'} onSelect={this.onNavMenuClick} active={this.props.vtype==='issued'}>Final IP</MenuItem>
                        <MenuItem eventKey={'pending'} onSelect={this.onNavMenuClick} active={this.props.vtype==='pending'}>Pending IP</MenuItem>
                        <MenuItem eventKey={'nepa_ea'} onSelect={this.onNavMenuClick} active={this.props.vtype==='nepa_ea'}>NEPA EA</MenuItem>
                        <MenuItem eventKey={'nepa_eis'} onSelect={this.onNavMenuClick} active={this.props.vtype==='nepa_eis'}>NEPA EIS</MenuItem>
                        <MenuItem eventKey={'emergency'} onSelect={this.onNavMenuClick} active={this.props.vtype==='emergency'}>Emergency</MenuItem>
                        <MenuItem eventKey={'wrda'} onSelect={this.onNavMenuClick} active={this.props.vtype==='wrda'}>WRDA</MenuItem>
                        <MenuItem eventKey={'dwhs'} onSelect={this.onNavMenuClick} active={this.props.vtype==='dwhs'}>DWHS</MenuItem>
                        <MenuItem eventKey={'jds'} onSelect={this.onNavMenuClick} active={this.props.vtype==='jds'}>AJD</MenuItem>
                        <MenuItem eventKey={'s408'} onSelect={this.onNavMenuClick} active={this.props.vtype==='s408'}>Section 408</MenuItem>
                    </Dropdown.Menu>
              </Dropdown>
            </div>
    
          </div>
        );
      }

    toggleAndZoomToSearch=()=>{
        if(this.props.mode!=='map'){
            this.propse.doChangeMode("map");
            this.zoomToSearch();
            //this.setState({"toggle":'map'},);
        }
        else{
            this.zoomToSearch();
        }
    }
 
    copyUrl=()=>{
      copyTextToClipboard(createUrlFromState(window.location.href,this.props));
    }

    zoomToSearch=()=>{
        zoomToFeatures(this.props.map,this.props.searchResult.results.features);
    }


    download=()=>{
        const maxActions=250000;
        const result = this.props.searchResult;
        if(result.total<=maxActions){
            let reportService = new SearchService({
                'url':`${APIHOST}/permits/report`
            });
            reportService.search(this.props.searchParams);
        }
        else{
            alert("You are attempting to download more than "+maxActions+" action.  Please reduce the number opf actions by refining the search term or applying filters.");
        }
    }

    renderSearchViews(){
        switch(this.props.mode){
            case "map":
                return (<GeospatialView/>)
            case "table":
                return (<TableView selectedIdentifier={this.state.selectedIdentifier} />)
            default:
                return null;
        }
    }
    
    renderHome(){
       const ormDataStatement = () => {
          if(SHOW_ORM_LAST_UPDATE_DATE === "true") {
            return (
              <>
                The data is current as of <b>{this.getOrmAsOfDate()}</b>.
              </>
            );
          }
          else {
            return null;
          }
        
        }
        const ormIndexJobStatement  = () => {
          if(SHOW_ORM_INDEX_JOB_DATE === "true") {
            return (
              <>
                The last data sync job ran at <b>{this.ormIndexJobDate()}</b>.
              </>
            );
          } else {
            return null;
          }
          
        }
       
        
        return(
            <div className="AppBody">
                <div className="Home">
                    <div className="HomeTitle">USACE Regulatory and Section 408 Publicly Available Data</div>
                    <div className="HomeWelcome">Welcome</div>
                    <div className="HomeTextContainer">
                        <div className="HomeText">                   
                          The U.S. Army Corps of Engineers (USACE), Regulatory program, regulates work and structures that are located in, under or over navigable waters of the United States under Section 10 of the Rivers and Harbors Act of 1899, the discharge of dredged or fill material into waters of the United States under Section 404 of the Clean Water Act, and the transportation of dredged material for the purpose of disposal in the ocean (regulated by the Corps under Section 103 of the Marine Protection, Research and Sanctuaries Act). "Waters of the United States" are navigable waters, tributaries to navigable waters, wetlands adjacent to those waters, and/or isolated wetlands that have a demonstrated interstate commerce connection.
                          <div className="HomeSection">
                            <div className="HomeHeadingText">All Content</div>
                              The following action items can be viewed using this tool. The first tab option, “All Content”, will show all content included on the site for all the tabs described below. Each of these tabs contains a link to the map where permits can be searched and displayed via the map or table view to see the location of the selected project and some basic project information.  {ormDataStatement()}  {ormIndexJobStatement()}
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Final IPs / Pending IPs</div>
                              Individual Permits (IPs) are two types of Regulatory permits that the USACE can issue under program authorities. Individual permits include Standard Permits, which are generally more complex in nature and involve notification of the public and commenting agencies, and Letters of Permission, a type of permit issued through an abbreviated processing procedure which includes coordination with Federal and State fish and wildlife agencies, as required by the Fish and Wildlife Coordination Act, and a public interest evaluation, but without the publishing of an individual public notice. In compliance with our regulations at 33 CFR 325.2(a)(8), we are posting a list of issued and denied individual permits. Pending individual permit applications, which have been determined to be "federally complete" but not yet authorized, are also posted to increase Regulatory Program transparency. To be considered federally complete, the application is to contain the information required by regulations at 33 CFR 325.1(d)(9), Comments on any active public notice listed on the Pending IP page can be provided through the <u><a href={`${REGULATORY_REQUEST_SYSTEM}`} target="_blank">Regulatory Request System public notice section</a></u>.
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">NEPA Reviews</div>
                               The NEPA EA and NEPA EIS tabs includes a list of environmental assessments (EAs) and environmental impact statements (EISs) that are pending or were completed by the USACE Regulatory Program pursuant to the National Environmental Policy Act (NEPA). The USACE Regulatory Program completes project-specific NEPA reviews (either an EA or EIS) for standard permit and third-party compensatory mitigation instrument decisions, and during the process to Develop Regional and Programmatic General Permits.  The Program’s NEPA-implementing procedures can be found <u><a href={`${NEPA_URL}`} target="_blank">here</a></u>.<p></p>
                               Both the EA and EIS lists include the name of the USACE District that is undertaking or completed the NEPA review, the Department of the Army file number for the review (“DA Number”), the name of the applicant for the corresponding permit application or mitigation instrument proposal, and the project name.<p></p>
                               In the EA list, the “EA Action Type” column identifies if the EA is for a standard permit application, an in-lieu fee program instrument, a mitigation bank instrument, or the development of a Regional or Programmatic General Permit. The EA list also includes the date the district issued a public notice for a standard permit application, a proposed new or reissued Regional or Programmatic General Permit, or a proposed mitigation bank or ILF program, and, where applicable, the date the district received a complete draft mitigation instrument from the bank or ILF fee program sponsor. In the EA list, the “Final EA Publication Date” corresponds to the date the EA was listed as published on this website.  <p></p>
                               The list of EISs is limited to EISs where the USACE Regulatory Program is the lead agency under NEPA.  The EIS list also includes dates for major milestones in the NEPA process, as follows: the date USACE determined an EIS was needed (i.e., the “Date of the Significance Determination”); the date a Notice of Intent to prepare an EIS was published in the Federal Register; and the dates the Notice of Availability of the Draft EIS and the Final EIS were published in the Federal Register, respectively.  If a major milestone has not yet been reached for a given EIS, the field will be blank. The EIS list also includes a link to the responsible USACE District’s webpage that includes more information on the EIS, such as NEPA documents and a NEPA schedule.  <p></p>
                               Additional information on these EAs and EISs can be obtained by contacting the responsible <u><a href={`${DISTRICT_OFFICE_FINDER_URL}`} target="_blank">USACE District</a></u>.
                          </div>
                        
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Emergency</div>
                              All Emergency Permits issued under the Alternative Permit procedures (33 CFR 325 (e)(4)) as well as any pending applications are shown.
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Water Resources Development Act (WRDA)</div>
                              Content under the WRDA tab shows projects that are associated with Section 214 of the Water Resources Development Act (WRDA) of 2000, as amended (33 U.S.C. 2352) and/or Title 23 of the U.S. Code, Section 139(j) (Transportation Agreements) that provides the authority to accept and expend funds from certain entities to expedite the review of permit applications.
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Deepwater Horizon (DWHS)</div>
                              DWHS projects are those funded through the Deepwater Horizon oil spill restoration efforts. Examples of the funding sources include: Resources and Ecosystem Sustainability, Tourism Opportunities and Revived Economy of the Gulf Coast Act of 2012 (RESTORE), the National Fish and Wildlife Foundation (NFWF), and Natural Resources Damage Assessment (NRDA). Pending applications, final permit decisions, jurisdictional determinations and pre-application meetings for these projects will be displayed.
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Approved Jurisdictional Determinations (AJDs)</div>
                              The AJDs tab includes the location and information about Approved Jurisdictional Determinations (AJDs). All final AJDs will be posted on this site. Information providing the USACE tracking number, project name, overall project location, and the final AJD basis document and map will be provided. Links to the AJD basis document form and map are posted on district websites and will be visible until the expiration date of the AJD. Additional information regarding these AJDs can be obtained by contacting the district office that issued them.
                          </div>
                          <div className="HomeSection">
                            <div className="HomeHeadingText">Section 408</div>
                              The Section 408 tab shows projects requiring Section 408 authorization. Through the Civil Works program, the USACE serves the public by providing the Nation with quality and responsive management of the Nation’s water resources. As a result, USACE, in partnership with stakeholders, has constructed many Civil Works projects across the Nation’s landscape. Examples include Flood Risk Management, Navigation Improvements, Shore Protection, and Ecosystem Restoration projects. Given the widespread locations of these projects, many embedded within communities, over time there may be a need for others outside of USACE to alter or occupy these projects and their associated lands. Reasons for alterations could include improvements to the projects; relocation of part of the project; or installing utilities or other non-project features. In order to ensure that these projects continue to provide their intended benefits to the public, Congress mandated that any use or alteration of a Civil Works project by another party is subject to the approval of USACE. This requirement was established in Section 14 of the Rivers and Harbors Act of 1899, which has since been amended several times and is codified at 33 USC 408 (Section 408). Section 408 provides that USACE may grant permission for another party to alter a Civil Works project upon a determination that the alteration proposed will not be injurious to the public interest and will not impair the usefulness of the Civil Works project.
                          </div>
                        </div>  
                    </div>
                    <div className="HomeWelcomeBottom"></div>
                    For additional information about any of these listed actions please contact your local <u><a href={`${DISTRICT_OFFICE_FINDER_URL}`} target="_blank">Regulatory district office</a></u>.
                    <div className="HomeText" onClick={this.onAgree}>
                        <button>Click here to get started looking for permits.</button>
                    </div>
                  
                </div>
            </div>
        )
    }

    renderBody(){
        return(
            <div className="AppBody">
                <div className='AppBodyQueryBarTitle'>
                   <b>{getVTitle(this.props.vtype)}</b>
                   <div title="Copy URL" style={{"float":'right'}} onClick={this.copyUrl}>
                      <img src={`${PUBLIC_URL}/copy.svg`}/>
                   </div>
                </div>
                <div className="AppBodyQueryBar">
                    <table>
                    <tr><td>
                    <SearchEntry />
                    </td><td>
                    <SearchFilters
                        setFilterParam={this.setFilterParam}             
                    />
                    </td><td>
                    {(this.props.searchResult.results.features.length>0)?(
                        <div className="SearchIcons">
                        {this.props.mode==="map"?
                            <div title="Zoom to Permit List" style={{"float":'left'}} onClick={this.toggleAndZoomToSearch}>
                                <img src={`${PUBLIC_URL}/zoom-all-grey.png`}/>
                            </div>:null
                        }
                        {this.props.mode==="map" || (this.props.mode==="table" )?                                    //&& !(this.props.q==="" && this.isEmpty(this.props.filterParams))
                            <div title="Download the Permit List" style={{"float":'left'}} onClick={this.download}>
                                <img src={`${PUBLIC_URL}/download-grey.png`}/>
                            </div>:null
                        }
                        </div>
                        
                        ):null
                    }                   
                    </td><td></td>
                    {(this.props.vtype === "nepa_ea")?(
                        <td>{this.renderEaActionTypeTable()}</td>):null
                    }
                    </tr>
                    </table>
                    <SearchPagenator />
                </div>
                {this.renderSearchViews()}
            </div>
        );
    }

    renderAppViewIcons(){
        let toggleClass="glyphicon glyphicon-globe";
        let toggleText="Map View";
        if(this.props.mode==='table'){
          toggleClass="glyphicon glyphicon-globe";
          toggleText="Map View"
        }
        else if(this.props.mode==='map'){
          toggleClass="glyphicon glyphicon-th-list";
          toggleText="Table View"
        }
        return(
          <div>
            <div className="panelToggle"  >
              <div className="table">
                <div className="tableRow">
                  <div className="tableItem">    
                    <div className={toggleClass} onClick={this.onViewToggleClick} toggleval={toggleText}/>
                  </div>
                  <div className="tableItem">    
                    <div className="tableItem" 
                         style={{"fontFamily":'"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif',"fontSize":"14px","fontWeight": "bold"}}
                         toggleval={toggleText}
                         onClick={this.onViewToggleClick}
                    >{toggleText}</div>
                  </div>
                  {(this.state.toggle==='home')?(
                  <div>
                    <div style={{"paddingLeft":"15px"}} className="tableItem">    
                      <div className="glyphicon glyphicon-th-list" onClick={this.onViewToggleClick} toggleval="Table View"/>
                    </div>
                    <div className="tableItem">    
                      <div className="tableItem" 
                          style={{"fontFamily":'"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif',"fontSize":"14px","fontWeight": "bold"}}
                          toggleval="Table View"
                          onClick={this.onViewToggleClick}
                      >Table View</div>
                    </div>
                  </div>
                  ):(
                    // <div>
                    //   <div style={{"paddingLeft":"15px","paddingRight":"5px",}} className="tableItem">    
                    //     <div title="Click here to provide feedback." onClick={this.onFeedbackOpen} className="glyphiconFeedback glyphicon-comment" />
                    //   </div>
                    //   <div
                    //     style={{"fontFamily":'"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif',"fontSize":"14px","fontWeight": "bold"}}
                    //     className="tableItem"
                    //     onClick={this.onFeedbackOpen} 
                    //   >Feedback</div>
                    // </div>
                    <div></div>
                  )} 
                </div>
              </div>
            </div>
            {/* <Feedback 
              display={this.state.feedbackModal}
              feedbackClose={this.feedbackClose}
            /> */}
            </div>
        );
      }
      
      renderEaActionTypeTable(){
        return(
            <div style={{"display": "inline-block","font-size": "12px","fontWeight": "bold"}}>
                <table>
                    <tr>
                        <td>EA Action Types:&nbsp;&nbsp;
                            SP:  &nbsp;&nbsp;Standard Permit,&nbsp;&nbsp;
                            DEVINLIEUA:  &nbsp;&nbsp;Develop In-Lieu Fee Program
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            DEVMBA:  &nbsp;&nbsp;Develop Mitigation Bank,&nbsp;&nbsp;
                            DEVRPSS:  &nbsp;&nbsp;Develop RGP/PGP/SPGP/Section 408-LOP&nbsp;&nbsp;
                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    render(){
        return(
            <div className="App">
                {this.state.navMenu?(
                    this.renderNavbarMenu() 
                    ):
                    (this.renderNavbar())
                }
                {(this.props.mode==="")?null:  
                (this.props.mode==='home')? 
                    (this.renderHome()):
                    (this.renderBody())
                }
                {this.renderAppViewIcons()}
            </div>
        );
    }
}

export default connect(
    'doGetNaturalDisasterEventList',
    'doGetOrmIndexJobDate',
    'doGetOrmAsOfDate',
    'doSetInitialView',
    'doChangeMode',
    'doChangeModeState',
    'doAgree',
    'doChangeQ',
    'doClearSearch',
    'doSearch',
    'doUpdateStartRow',
    'doChangeSelectedIdentifier',
    'doUpdateFilterParam',
    //'selectSelectedIdentifier',
    'selectMode',
    'selectQ',
    'selectFilterParams',
    'selectSearchParams',
    'selectVtype',
    'selectMap',
    'selectSearchResult',
    'selectOrmIndexJobDate',
    'selectGetOrmAsOfDate',
    Root
);