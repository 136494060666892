
let zoomToFeatures=(map,features)=>{
    let env=getEnvelope(features);
    map.fitBounds([
        [env[0][1], env[0][0]],
        [env[1][1], env[1][0]]
    ]);
}


let getEnvelope=function(results){
    let seed=results[0].geometry.coordinates;
    let ll=[seed[0],seed[1]];
    let ur=[seed[0],seed[1]];
    results.forEach((result)=>{
        const c=result.geometry.coordinates;
        if(c[0]<ll[0]) ll[0]=c[0];
        if(c[1]<ll[1]) ll[1]=c[1];
        if(c[0]>ur[0]) ur[0]=c[0];
        if(c[1]>ur[1]) ur[1]=c[1];
    });
    return [ll,ur];
}

export {zoomToFeatures, getEnvelope};