import {OrmPermit} from './OrmPermit.js';

export class DwhsPermit extends OrmPermit{

    constructor(props){
        super(props);
        this.fields={
            publicNoticeDate:"Public Notice Date",
            actionTaken:"Action Taken",
            displayDate:"Date Issued/Denied",
            fundingSources:"Funding Sources"
        }
    }
}