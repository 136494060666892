const APIHOST = process.env.REACT_APP_APIHOST;
export class HexbinLayer {

    constructor(map){
        this.map=map;
        this.vtype=null;
    }

    loadData=(vtype)=>{
        let xhr = new XMLHttpRequest();
        let geodataUrl=`${APIHOST}/data/`;
        this.hbSeries=(this.map.getZoom()>8)?"12k":"25k";
        if(vtype){
            geodataUrl=geodataUrl+"hb_"+vtype+"_"+this.hbSeries+".json";
        }
        else{
            geodataUrl=geodataUrl+"hb_all"+"_"+this.hbSeries+".json";
        }
        xhr.open('GET', geodataUrl);
        xhr.onreadystatechange = ()=>{
          if (xhr.readyState > 3 && xhr.status === 200) {
              let geodata = JSON.parse(xhr.responseText);
              this.geodata=geodata;
              this.drawLayer();
              this.vtype=vtype;
          }
        };
        xhr.send();
    }

    drawLayer=()=>{
        if(this.layer)
            this.layer.remove();
        this.layer=window.L.geoJson(this.geodata,{style: this.style}).addTo(this.map);
    }
    
    show(vtype){
        if(vtype!==this.vtype || !this.layer || this.mapSeriesChange()){
            this.hide();
            this.loadData(vtype);
        }
        else{
            this.map.addLayer(this.layer);
        }   
    }

    mapSeriesChange=()=>{
        let newSeries='25k';
        if(this.map.getZoom()>8){
            newSeries='12k'
        }
        return(!(this.hbSeries===newSeries));
    }

    changePalette=(palette)=>{
        switch(palette){
            case "green":
                this.colorValues=this.colorValuesGreen;
                break;
            case "blue":
                this.colorValues=this.colorValuesBlue;
                break;
            default:
                this.colorValues=this.colorValuesOrange;
        }
        this.drawLayer();
    }
    
    hide(){
        if(this.layer)
            this.map.removeLayer(this.layer);
    }

    style=(feature)=>{
        return {
            fillColor: this.getColor(feature.properties.count),
            weight: 0.1,
            opacity: 0.5,
            color: 'black',
            fillOpacity: 0.5
        };
    }

    colorValuesOrange=[{val:50,color:'#7f2704'},
                       {val:25,color:'#d14501'},
                       {val:10,color:'#f67722'},
                       {val:5,color:'#fdac67'},
                       {val:0,color:'#fedbb7'}];

    colorValuesGreen=[{val:50,color:'#00441b'},
                      {val:25,color:'#1d8641'},
                      {val:10,color:'#54b567'},
                      {val:5,color:'#9ed898'},
                      {val:0,color:'#d5efcf'}];

    colorValuesBlue=[{val:50,color:'#08306b'},
                     {val:25,color:'#1c6cb1'},
                     {val:10,color:'#529dcc'},
                     {val:5,color:'#9ac8e1'},
                     {val:0,color:'#d1e3f3'}];

    colorValues=this.colorValuesGreen;

    getColor=(val)=>{
        for(let i=0;i<this.colorValues.length;i++){
            if(val>this.colorValues[i].val)
                return this.colorValues[i].color;
        }
        return this.colorValues[this.colorValues.length-1].color; //if for some reason we have zero or negative values, return lowest color
    }
    
}