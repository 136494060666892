import React from 'react'


let orgSelect=function(onChange,queryParams,labelwidth){
    let lw=(labelwidth===undefined)?"inherit":labelwidth.toString()+"px";
    let org=(queryParams.org===undefined)?"":queryParams.org;
    return(
        <div title="Choose a USACE Division or District" className="tableItem">
            <div className="tableItem" style={{"width":lw}}>
            Filter by District:
            </div>
            <div className="tableItem">  
            <select id="org"  value={org} className="selectlist" size="1" onChange={onChange}>
            <option value="">- All Districts -</option>
            <option value="MV?">----MVD-Mississippi Valley Division</option>
            <option value="MVM">------MVM-Memphis District</option>
            <option value="MVN">------MVN-New Orleans District</option>
            <option value="MVS">------MVS-St. Louis District</option>
            <option value="MVK">------MVK-Vicksburg District</option>
            <option value="MVR">------MVR-Rock Island District</option>
            <option value="MVP">------MVP-St. Paul District</option>
            <option value="NA?">----NAD-North Atlantic Division</option>
            <option value="NAB">------NAB-Baltimore District</option>
            <option value="NAN">------NAN-New York District</option>
            <option value="NAO">------NAO-Norfolk District</option>
            <option value="NAP">------NAP-Philadelphia District</option>
            <option value="NAE">------NAE-New England District</option>
            <option value="NW?">----NWD-Northwestern Division</option>
            <option value="NWP">------NWP-Portland District</option>
            <option value="NWS">------NWS-Seattle District</option>
            <option value="NWW">------NWW-Walla Walla District</option>
            <option value="NWK">------NWK-Kansas City District</option>
            <option value="NWO">------NWO-Omaha District</option>
            <option value="LR?">----LRD-Great Lakes and Ohio River Division</option>
            <option value="LRH">------LRH-Huntington District</option>
            <option value="LRL">------LRL-Louisville District</option>
            <option value="LRN">------LRN-Nashville District</option>
            <option value="LRP">------LRP-Pittsburgh District</option>
            <option value="LRB">------LRB-Buffalo District</option>
            <option value="LRC">------LRC-Chicago District</option>
            <option value="LRE">------LRE-Detroit District</option>
            <option value="PO?">----POD-Pacific Ocean Division</option>
            <option value="POH">------POH-Honolulu District</option>
            <option value="POA">------POA-Alaska District</option>
            <option value="SA?">----SAD-South Atlantic Division</option>
            <option value="SAC">------SAC-Charleston District</option>
            <option value="SAJ">------SAJ-Jacksonville District</option>
            <option value="SAM">------SAM-Mobile District</option>
            <option value="SAS">------SAS-Savannah District</option>
            <option value="SAW">------SAW-Wilmington District</option>
            <option value="SP?">----SPD-South Pacific Division</option>
            <option value="SPL">------SPL-Los Angeles District</option>
            <option value="SPK">------SPK-Sacramento District</option>
            <option value="SPN">------SPN-San Francisco District</option>
            <option value="SPA">------SPA-Albuquerque District</option>
            <option value="SW?">----SWD-Southwestern Division</option>
            <option value="SWF">------SWF-Fort Worth District</option>
            <option value="SWG">------SWG-Galveston District</option>
            <option value="SWL">------SWL-Little Rock District</option>
            <option value="SWT">------SWT-Tulsa District</option>
            </select>
            </div>
        </div>
    );
}

let yearSelect=function(onChange,queryParams,labelwidth){
    let lw=(labelwidth===undefined)?"inherit":labelwidth.toString()+"px";
    let thisYear=new Date().getFullYear();
    let year=(queryParams.year===undefined)?"ALL":queryParams.year;
    return(
        <div title="Choose a specific year or all years" className="tableItem">
            <div className="tableItem"  style={{"width":lw}}>
                Year:
            </div>
            <div className="tableItem">
                <select id="year" value={year} className="selectlist" size="1" onChange={onChange}>
                <option value="ALL">All Years</option>
                {Array.apply(null, {length:10}).map(function(obj,i){
                    let theYear=thisYear-parseInt(i);
                    return <option value={theYear.toString()} id={theYear.toString()}>{theYear}</option>
                })} 
                </select>
            </div>
        </div>
    );
}

let monthSelect=function(onChange,queryParams,labelwidth){
    let lw=(labelwidth===undefined)?"inherit":labelwidth.toString()+"px";
    let month=(queryParams.month===undefined)?"0":queryParams.month;
    return(
        <div title="Choose a month or Year to Date" className="tableItem">
            <div className="tableItem" style={{"width":lw}}>
                Month:
            </div>
            <div className="tableItem">
                <select id="month" value={month} style={{"margin-right":"0px"}} className="selectlist" size="1" onChange={onChange}>
                <option value="0">Year to Date</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
                </select>
            </div>
        </div>
    )
}

let eventSelect=function(onChange,queryParams,events,labelwidth){
    let lw=(labelwidth===undefined)?0:labelwidth;
    let event=(queryParams.event===undefined)?"":queryParams.event;
    let wkEvents = (events === null) ? [] : events;
    return(
        <div className="tableItem">
            <div className="tableItem" style={lw>0?{}:{"width":lw}}>
                Filter by Event:
            </div>
            <div className="tableItem">
                <select id="event" value={event} className="selectlist" size="1" onChange={onChange}>
                <option value="" selected="selected">All Events</option>
                {wkEvents.map(function(obj,i){
                    return <option value={obj}>{obj}</option>
                })}
                </select>
            </div>
        </div>
    )
}

export {orgSelect,yearSelect,monthSelect,eventSelect};