import React,{Component} from 'react';
import SubAction from './SubAction';

class SubActions extends Component{

    subactionNames={
        "esa":"Endangered Species Act",
        "efh":"Essential Fish Habitat",
        "s106Nhpa":"Section 106 of the NHPA",
        "wqc401":"401 Water Quality Certification Coordination"
    }

    render(){
        return(
            <div>
                {this.subactionNames[this.props.subActionType]}
                <div className="PermitSubActionCategory">
                    {this.props.subactions.map((subaction,index)=>{
                        if(index===0)
                            return(<SubAction result={subaction} type={this.props.subActionType}/>);
                        else
                            return(<div><div className="PermitSubActionSeparator"></div><SubAction result={subaction} type={this.props.subActionType}/></div>);
                    })}
                </div>           
            </div>
        )
    }
}

export default SubActions;