import QueryBuilder from './QueryBuilder';

const APIHOST = process.env.REACT_APP_APIHOST;

class SearchService{

    constructor(config){
        this.url=config.url;
        this.xhr=null;
        this.maxDisplayItems=25
    }


    setMaxDisplayItems=(max)=>{
        this.maxDisplayItems=max;
    }
    

    search=(params,callback)=>{
        let url = this.buildUrl(params);
        if(url){
            if(url.indexOf("report")>0){
                window.open(url,'_blank');
            }
            else{
                if(this.xhr && this.xhr.readyState<4){
                    this.xhr.abort();
                }
                this.xhr = new XMLHttpRequest();
                this.xhr.open('GET', url);
                this.xhr.onreadystatechange = function(){
                    if (this.xhr.readyState > 3 && this.xhr.status === 200) {
                        let searchresult = JSON.parse(this.xhr.responseText);
                        //this.onSuccess(searchresult);
                        callback(searchresult);
                    }
                }.bind(this);
                this.xhr.send();
            }
        }
    }

    clearSearch=(params)=>{
        let filterParams=params.filterParams;
        if(!params.map.geoFence.isActive()){
            if(!params.q){
                if(params.vtype==="emergency"){
                    if(filterParams.event ===undefined || filterParams.event===""){
                        return true;
                    }
                }
                else{
                    if(filterParams.org === undefined || filterParams.org===""){
                        if(filterParams.year===undefined || filterParams.year==="ALL"){
                            //if(filterParams.month="0"){
                                return true;
                            //}
                        }
                    }
                }
            }
        }
        return false;
    }

    /*
        acceptable params:
        da: boolean, essentually deduplicates search on DA Number
        filterParams
        q
    */

    buildUrl=(params)=>{
        let da=params.da;
        const filterParams=params.filterParams;
        
        if(da===undefined)
            da='true';

        let q=new QueryBuilder({
            ...filterParams,
            'vtype':params.vtype,
            'q':params.q
        }).buildQuery();

        let bboxString="";
        if(params.map){
            bboxString="&bbox=" + params.map.getSearchBounds().toBBoxString();
        }
        else {
            bboxString="&bbox=-180, -90, 180, 90";
        }

        let geodataUrl;

        let datype="";
        if(da && params.vtype!=='all'){
            datype='&datype='+params.vtype;
        }

        if(params.map && params.map.geoFence.isActive()){
            geodataUrl=this.url+"/fence?da="+da+datype+"&q="+q+bboxString; 
        }
        else{
            geodataUrl=this.url+"?da="+da+datype+this.addSort(params)+"&max="+this.maxDisplayItems+"&start="+params.start+"&q="+q+bboxString;     
        }
        return geodataUrl;
    }

    addSort=(params)=>{
        let paramString=""
        if(params.sort){
            paramString="&sort="+params.sort
            if(params.desc){
                paramString+="&desc="+params.desc
            }
        }
        return paramString;
    }

    resultsAreSame=(newResults)=>{
        let newDa=this.getDaNumberList(newResults);
        let oldDa=this.getDaNumberList(this.state.results);
        if(newDa.length===oldDa.length){
            newDa.sort();
            oldDa.sort();
            for(let i=0;i<newDa.length;i++){
                if(newDa[i]!==oldDa[i]){
                    return false;
                }
            }
            return true;
        }
        else{
            return false;
        }
    }

}

export default SearchService;