import React,{Component} from 'react';
import {PermitsLayer} from './PermitsLayer';

const styles=PermitsLayer.styles;

export class PermitLegend extends Component{

    renderStyle(legendItem){
        return(
            <div className="tableRow">
                <div className="tableItem" style={{"verticalAlign":"middle"}}>
                    <div className="circleStyle" style={{"backgroundColor":legendItem.color}}></div>
                </div>
                <div className="tableItem">
                    {legendItem.text}
                </div>
            </div>
        );
    }

    render(){
        return(
            <div className="PermitLegend">
                <div className="table">
                    {Object.keys(styles).map((key)=>{
                        return(this.renderStyle(styles[key]));
                    })}       
                </div>
            </div>
        )
    }
}