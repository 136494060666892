
function createCustomAlert(message) {
    const alertBox = document.getElementById('customAlert');
    alertBox.textContent = message;
    alertBox.style.display = 'block';
  
    setTimeout(function() {
      alertBox.style.display = 'none';
    }, 4000); // Hide after 3 seconds (3000 milliseconds)
  }

export {createCustomAlert};
