import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';
import {FlexGrid} from 'wijmo/wijmo.react.grid';
import {FlexGridFilter, SortDescription} from 'wijmo/wijmo.grid.filter';
import {orgSelect,yearSelect,monthSelect,eventSelect} from './TableUtils.js';
import {OrmEvents} from '../OrmEvents.js';
import CSV from './Csv.js';
import FileSaver from 'file-saver';
import {SearchResult} from '../search/SearchResult';
import { bind } from 'lodash';
//import { connect } from 'http2';

const sortAscArrow=' ▼';
const sortDescArrow=' ▲';

let setHeaderText=function(columns, binding, sortDesc){
    columns.forEach((col)=>{
        if(col.binding===binding){
            col.header = col.header+((sortDesc===true)?sortDescArrow:sortAscArrow);    
            console.log(col.header);
        }
    })
}

let removeHeaderArrows=function(columns){
    columns.forEach((col)=>{
        let lastChar=col.header.substring(col.header.length-1);
        if(lastChar===sortAscArrow || lastChar===sortDescArrow){
            col.header=col.header.substring(0,col.header.length-1);
        }
    })
}

//abstract class
class PermitGrid extends Component{

    constructor(props){
        super(props);
        this.state={maxheight:0};
        this.rowref=[];
        this.exportCsv = OrmEvents.addListener('exportCsv', () => {
            this.toCsv();
        });
        this.sort=null;

    }

    componentDidMount(){
        window.addEventListener("resize", this.resizeGrid.bind(this));
        this.setState({'maxheight':window.innerHeight-220});
        this.renderDelay()
    }
    renderWijmoFix() {
        const links = document.querySelectorAll("a[href]");
        if (links.length > 0) {
          links.forEach((link)=>{
            if(link.href.includes('http://wijmo.com/products/wijmo-5/eval/')) {
              link.target = "_blank";
            }
        })
        }
      }
  
      renderDelay() {
          setTimeout(this.renderWijmoFix(), 50)
      }
      
    resizeGrid(e){
        this.setState({'maxheight':window.innerHeight-220});
    }

    toCsv(){
        let self=this;
        let data=[];
        let header=false;
        this.rowref.forEach(function(row){
            let rec=row.dataItem;
            let recOut=[];
            let h=[];
            self.columnBindings.forEach(function(col){
                if(!header){
                    h.push(col.header); 
                }
                recOut.push(rec[col.binding]);    
            });
            data.push(recOut);
            if(!header)
                header=h
        });
        let csv=CSV.encode(data, { 'header': header });
        var blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "permits.csv");
    }

    formatMapCell=(cell)=> {
      let html = '<div><span class="glyphicon glyphicon-globe"></span></div>';
      cell.innerHTML = html;
    }

    formatAjdLinkCell=(cell)=> {
        let html = '<div><a href="'+cell.innerHTML+'" target="_blank">'+SearchResult.permitTypeStandard+' Document Link </a></div>';
        cell.innerHTML = html;
    }

    render(){
        let self=this;
        return(
            <div>
                <FlexGrid
                    autoRowHeights={true}
                    autoGenerateColumns={false}
                    columns={self.columnBindings}
                    itemsSource={this.props.data}
                    style={{"max-height":this.state.maxheight}}
                    initialized={ function(s, e) {
                        // s.columns.getColumn('noticeOfAvailDraftEisDate').wordWrap=true;
                        //auto size rows
  	                    s.autoSizeRows(0,s.rows.length);
                        
                        s.autoSizeRow(0, true); 
                        s.selectionChanged.addHandler(function(s,e){
                            let daNumber=s.rows[e.row].dataItem.daNumber;
                            OrmEvents.emit('onSelectDaNumber',daNumber);
                        });
                        s.loadedRows.addHandler(function(s,e){
                            console.log("Loaded Rows")
                            removeHeaderArrows(s.columns);
                            s.autoSizeRows(0,s.rows.length);
                        })
                        s.refreshing.addHandler(function(s,e){
                            console.log("Refreshing grid");
                            if(self.sort){
                                setHeaderText(s.columns,self.sort.binding,self.sort.sortDesc);
                            }
                            s.autoSizeRows(0,s.rows.length);
                        })
                        s.addEventListener(s.hostElement, 'click', function(e) {
                            console.log(e.target);
                            let a=(e.target.className);
                            let tableSort={};
                            if(a.indexOf("wj-header")>-1){
                                const b=self.columnBindings;
                                for(let i=0;i<b.length;i++){
                                    if(e.target.innerText.trim().indexOf(b[i].header)>-1){
                                        tableSort.binding=b[i].binding
                                        tableSort.fieldName=b[i].header;
                                        if(self.sort && self.sort.binding===tableSort.binding){
                                            tableSort.sortDesc=(!self.sort.sortDesc);
                                        }
                                        else{
                                            tableSort.sortDesc=false
                                        }
                                    }
                                }
                                
                                
                            }
                            self.sort=tableSort;
                            self.props.doUpdateSort(tableSort);
                            
                            
                        }, true); // get the event before the grid does

                        /*
                        s.addEventListener(s.hostElement, 'click', function(e) {
                            var ht = s.hitTest(e);
                            if (ht.panel == s.cells && ht.col===0) {
                                let daNumber=s.rows[ht.row].dataItem.daNumber;
                                OrmEvents.emit('onSelectDaNumber',daNumber);
                                OrmEvents.emit('toggleViewToMap');
                                //console.log('you clicked cell ' + ht.row + ', ' + ht.col);
                            } 
                        }, true); // get the event before the grid does
                        */


                        self.rowref=s.rows;
                        
                        /* disable filter for version 1.  
                        let filter=new FlexGridFilter(s);
                        filter.filterApplied.addHandler(function (s, e) {
                            //var cf = filter.getColumnFilter(e.col);
                            self.rowref=s.grid.rows;
                            self.props.setFilteredRecordCount(s.grid.rows.length);
                        });
                        */
                    }}
                    formatItem= {function (s, e) {
                        if (e.panel == s.cells) {
                            var item = s.rows[e.row].dataItem;
                            switch (s.columns[e.col].header) {
                                case 'Map':
                                    self.formatMapCell(e.cell);
                                    break;
                                case 'Link to AJD Form':
                                    self.formatAjdLinkCell(e.cell);
                                    break;
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

////////implementations
class IssuedGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'district', header: 'District', allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number', allowSorting: false, width:"3*", wordWrap: true  },
            { binding: 'applicant', header: 'Applicant', allowSorting: false, width:"9*", wordWrap: true },
            { binding: 'projectName', header: 'Project Name', allowSorting: false, width:"9*", wordWrap: true },
            { binding: 'permitType', header: 'Permit Type', allowSorting: false, width:"4*", wordWrap: true },
            { binding: 'publicNoticeDate', header: 'Public Notice Date', allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'dateReceived', header: 'Federally Complete Date', allowSorting: false, width:"3.25*", wordWrap: true},
            { binding: 'actionTaken', header: 'Action Taken', allowSorting: false, width:"5*", wordWrap: true},
            { binding: 'displayDate', header: 'Date Issued/Denied', allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',width:"2*", allowSorting: false, format:"F4"},
            { binding: 'lat', header: 'Latitude',width:"2*", allowSorting: false, format:"F4"}
        ]
    }

    static queryForm(onChange,queryParams){
        return(
                <div className='TableQueryForm'>  
                {orgSelect(onChange,queryParams)}
                {yearSelect(onChange,queryParams)}
                {monthSelect(onChange,queryParams)}
                </div>
        );
    }
}


let ig=connect(
    'doUpdateSort',
    IssuedGrid
)
export {ig as IssuedGrid}


class PendingGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'district', header: 'District', allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'permitType', header: 'Permit Type',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'publicNoticeDate', header: 'Public Notice Date',allowSorting: false, width:"2.75*", wordWrap: true},
            { binding: 'dateReceived', header: 'Federally Complete Date',allowSorting: false, width:"2.75*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
        ]
    }
    static queryForm(onChange,queryParams){
        return(
            <div className='TableQueryForm'>
                {orgSelect(onChange,queryParams)}
            </div>
        );
    }

}

let pg=connect(
    'doUpdateSort',
    PendingGrid
)
export {pg as PendingGrid}

class EmergencyGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'event', header: 'Event', allowSorting: false, width:"4*", wordWrap: true },
            { binding: 'district', header: 'District', allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3.5*", wordWrap: true },
            { binding: 'state', header: 'State',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'county', header: 'County',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'permitType', header: 'Permit Type',allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'pnn', header: 'PNN',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'actionTaken', header: 'Action Taken',allowSorting: false, width:"5*", wordWrap: true},
            { binding: 'beginDate', header: 'Begin Date',allowSorting: false, width:"3*", wordWrap: true},       
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'status', header: 'Status',allowSorting: false, width:"2.5*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
        ]
    }
    static queryForm(onChange,queryParams,events){
        return(
            <div className='TableQueryForm'>
                {eventSelect(onChange,queryParams,events)}
            </div>
        );
    }
}

let eg=connect(
    'doUpdateSort',
    EmergencyGrid
)
export {eg as EmergencyGrid}

class WrdaGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'district', header: 'District', allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'permitType', header: 'Permit Type',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'publicNoticeDate', header: 'Public Notice Date',allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'actionTaken', header: 'Action Taken',allowSorting: false, width:"5*", wordWrap: true},
            { binding: 'displayDate', header: 'Date Issued/Denied',allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
        ]
    }
}

let wg=connect(
    'doUpdateSort',
    WrdaGrid
)
export {wg as WrdaGrid}

class DwhsGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'district', header: 'District', allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'permitType', header: 'Permit Type',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'publicNoticeDate', header: 'Public Notice Date',allowSorting: false, width:"2.5*", wordWrap: true},
            { binding: 'actionTaken', header: 'Action Taken',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'displayDate', header: 'Date Issued/Denied',allowSorting: false, width:"2.5*", wordWrap: true},
            { binding: 'fundingSources', header: 'Funding Sources',allowSorting: false, width:"6*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
        ]
    }
}     

let dg=connect(
    'doUpdateSort',
    DwhsGrid
)
export {dg as DwhsGrid}

class AjdGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'jdType', header: 'JD Type', allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'district', header: 'District', allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'displayDate', header: 'Date Finalized',allowSorting: false, width:"3*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'ajdUrl', header: 'Link to AJD Form',allowSorting: false, width:"2*", wordWrap: true}
        ]
    }
}

let ag=connect(
    'doUpdateSort',
    AjdGrid
)
export {ag as AjdGrid}

class S408Grid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'requestId', header: 'Request Id', allowSorting: false, width:"2.5*", wordWrap: true },
            { binding: 'requestName', header: 'Request Name', allowSorting: false, width:"5*", wordWrap: true },
            { binding: 'locationName', header: 'Location Name',allowSorting: false, width:"4*", wordWrap: true },
            { binding: 'status', header: 'Status',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'reviewCategory', header: 'Request Review Category',allowSorting: false, width:"2.5*", wordWrap: true },
            { binding: 'regulatoryPermitId', header: 'Regulatory Permit ID',allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'civilWorksProjects', header: 'Civil Works Projects',allowSorting: false, width:"5*", wordWrap: true },
            { binding: 'fundingAgreementType', header: 'Funding Agreement Type',allowSorting: false, width:"2.5*", wordWrap: true },
            { binding: 'initialSubmissionDate', header: 'Initial Submission Date',allowSorting: false, width:"2.5*", wordWrap: true },
            { binding: 'latestSubmissionDate', header: 'Last Submission Date',allowSorting: false, width:"2.5*", wordWrap: true }
        ]
    }
}

let s408g=connect(
    'doUpdateSort',
    S408Grid
)
export {s408g as S408Grid}


class NepaEaGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'districtOfficeDescription', header: 'District', allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'eaActionType', header: 'EA Action Type',allowSorting: false, width:"2*", wordWrap: true},
            { binding: 'pnDate', header: 'PN Date',allowSorting: false, width:"2*", wordWrap: true},
            { binding: 'dateComplDraftInstrRecd', header: 'Date Complete Draft Instrument Received (DEVMBA or DEVINLIEUA)',allowSorting: false, width:"5.75*", wordWrap: true},
            { binding: 'pendingOrFinal', header: 'Status',allowSorting: false, width:"2*", wordWrap: true},
            { binding: 'finalEaPublicationDate', header: 'Final EA Publication Date',allowSorting: false, width:"2.75*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
          
        ]
        
    }

}

let nepaEag=connect(
    'doUpdateSort',
    NepaEaGrid
)
export {nepaEag as NepaEaGrid}

class NepaEisGrid extends PermitGrid{
    constructor(props){
        super(props)
        this.columnBindings=[
            { binding: 'districtOfficeDescription', header: 'District', allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'daNumber', header: 'DA Number',allowSorting: false, width:"3*", wordWrap: true },
            { binding: 'pendingOrFinal', header: 'Status',allowSorting: false, width:"2*", wordWrap: true },
            { binding: 'applicant', header: 'Applicant',allowSorting: false, width:"9*",wordWrap: true },
            { binding: 'projectName', header: 'Project Name',allowSorting: false, width:"9*", wordWrap: true},
            { binding: 'signifDetermintationDate', header: 'Date of Significance Determination',allowSorting: false, width:"3.75*", wordWrap: true },
            { binding: 'dateNoiPublishedInFr', header: 'Date NOI Published in the FR',allowSorting: false, width:"3.5*", wordWrap: true },
            { binding: 'noticeOfAvailDraftEisDate', header: 'Date of Notice of Availability of the Draft EIS',allowSorting: false, width:"4.5*", wordWrap: true},
            { binding: 'noticeOfAvailFinalEisDate', header: 'Date of Notice of Availability of Final EIS',allowSorting: false, width:"4*", wordWrap: true},
            { binding: 'lon', header: 'Longitude',allowSorting: false, width:"2*", format:"F4"},
            { binding: 'lat', header: 'Latitude',allowSorting: false, width:"2*", format:"F4"}
        ]
    }
}

let nepaEisg=connect(
    'doUpdateSort',
    NepaEisGrid
)
export {nepaEisg as NepaEisGrid}