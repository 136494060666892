let contactData=[

    {"office":"Honolulu", "addr":"Honolulu District Regulatory Branch\n Building 230\n Fort Shafter, HI 96858-5440","phone":"808-438-9258", "web":"http://www.poh.usace.army.mil/Missions/Regulatory.aspx", "sym":"POH"},
    {"office":"Jacksonville", "addr":"Jacksonville District\n PO Box 4970\n Jacksonville, FL 32232-0019","phone":"904-232-1177","web":"http://www.saj.usace.army.mil/Missions/Regulatory.aspx","sym":"SAJ"},
    {"office":"Buffalo", "addr":"Buffalo District Regulatory Branch\n 1776 Niagara St\n Buffalo, New York 14207","phone":"716-879-4330","web":"http://www.lrb.usace.army.mil/Missions/Regulatory.aspx","sym":"LRB"},
    {"office":"Galveston","addr":"Galveston District Regulatory Branch\n 2000 Fort Point Road\n Galveston, TX 77550","phone":"409-766-3941","web":"http://www.swg.usace.army.mil/BusinessWithUs/RegulatoryBranch.aspx","sym":"SWG"},
    {"office":"New Orleans","addr":"New Orleans District, CEMVN-OD-S\n PO Box 60267\n New Orleans, LA 70160-0267","phone":"504-862-2255","web":"http://www.mvn.usace.army.mil/Missions/Regulatory.aspx","sym":"MVN"},
    {"office":"Los Angeles","addr":"Los Angeles District Office\n 915 Wilshire Blvd\n Los Angeles, CA 90017","phone":"213-452-3425","web":"http://www.spl.usace.army.mil/Missions/Regulatory.aspx","sym":"SPL"},
    {"office":"Fort Worth","addr":"Fort Worth District\n 819 Taylor Street,\n Room 3A37\n PO Box 17300\n Fort Worth, TX 76102-0300","phone":"817-886-1731","web":"http://www.swf.usace.army.mil/Missions/Regulatory.aspx","sym":"SWF"},
    {"office":"Mobile","addr":"Mobile Regulatory Division\n 109 Saint Joseph St\n Mobile, Alabama 36602","phone":"251-690-2658","web":"http://www.sam.usace.army.mil/Missions/Regulatory.aspx","sym":"SAM"},
    {"office":"Vicksburg","addr":"Vicksburg District\n Regulatory Branch\n 4155 Clay Street\n Vicksburg, MS 39183-3435","phone":"601-631-7071","web":"http://www.mvk.usace.army.mil/Missions/Regulatory.aspx","sym":"MVK"},
    {"office":"Savannah","addr":"Savannah District\n ATTN: CESAS-RD\n Post Office Box 889\n 100 West Oglethorpe Ave\n Savannah, GA 31402","phone":"912-652-5768","web":"http://www.sas.usace.army.mil/Missions/Regulatory.aspx","sym":"SAS"},
    {"office":"Charleston","addr":"Charleston District Regulatory Office\n 69 A Hagood Ave\n Charleston, SC 29403","phone":"843-329-8044","web":"http://www.sac.usace.army.mil/Missions/Regulatory.aspx","sym":"SAC"},
    {"office":"Wilmington","addr":"US Army Corps of Engineers\n 69 Darlington Avenue\n Wilmington, NC 28403","phone":"910-251-4633","web":"http://www.saw.usace.army.mil/Missions/RegulatoryPermitProgram.aspx","sym":"SAW"},
    {"office":"Tulsa","addr":"Tulsa District\n Regulatory Office-CESWT-RO\n 1645 South 101st East Ave\n Tulsa, OK 74128","phone":"918-669-7401","web":"http://www.swt.usace.army.mil/Missions/Regulatory.aspx","sym":"SWT"},
    {"office":"Memphis","addr":"Memphis District\n 167 North Main, B202 \n Memphis, TN 38103-1894","phone":"901-544-0738","web":"http://www.mvm.usace.army.mil/About/Offices/Regulatory.aspx","sym":"MVM"},	
    {"office":"Nashville","addr":"Nashville District\n 3701 Bell Road\n Nashville, Tennessee 37214","phone":"615-369-7500","web":"http://www.lrn.usace.army.mil/Missions/Regulatory.aspx","sym":"LRN"},	
    {"office":"Little Rock","addr":"Little Rock District\n CESWL-RO\n PO Box 867\n Little Rock, AR 72203-0867","phone":"501-324-5295","web":"http://www.swl.usace.army.mil/Missions/Regulatory.aspx","sym":"SWL"},	
    {"office":"San Francisco","addr":"US Army Corps of Engineers\n 1455 Market Street\n 16th Floor\n San Francisco, CA 94103-1398","phone":"415-503-6795","web":"http://www.spn.usace.army.mil/Missions/Regulatory.aspx","sym":"SPN"},	
    {"office":"Baltimore","addr":"Baltimore Regulatory Office\n PO Box 1715\n Baltimore, MD 21203-1715","phone":"410-962-3670","web":"http://www.nab.usace.army.mil/Missions/Regulatory.aspx","sym":"NAB"},
    {"office":"Norfolk","addr":"Norfolk District Office\n ATTN: CENAO-REG\n 803 Front St\n Norfolk, VA 23510","phone":"757-201-7652","web":"http://www.nao.usace.army.mil/Missions/Regulatory.aspx","sym":"NAO"},
    {"office":"Albuquerque","addr":"Albuquerque Regulatory Office\n 4101 Jefferson Plaza NE\n Room 313\n Albuquerque, NM 87109-3435","phone":"505-342-3262","web":"http://www.spa.usace.army.mil/Missions/RegulatoryProgramandPermits.aspx","sym":"SPA"},
    {"office":"St. Louis","addr":"St. Louis District\n 1222 Spruce Street\n St. Louis, MO 63103-2833","phone":"314-331-8575","web":"http://www.mvs.usace.army.mil/Missions/Regulatory.aspx","sym":"MVS"},
    {"office":"Kansas City","addr":"Kansas City District Office\n 635 Federal Building\n Room 402\n Kansas City, MO 64106","phone":"816-389-3990","web":"http://www.nwk.usace.army.mil/Missions/RegulatoryBranch.aspx","sym":"NWK"},
    {"office":"Huntington","addr":"Huntington District\n 502 Eighth Street\n Huntington, West Virginia 25701-2070","phone":"304-399-5211","web":"http://www.lrh.usace.army.mil/Missions/Regulatory.aspx","sym":"LRH"},
    {"office":"Louisville","addr":"Louisville District\n OP-F, Room 752\n PO Box 59\n Louisville, KY 40201-0059","phone":"502-315-6733","web":"http://www.lrl.usace.army.mil/Missions/Regulatory.aspx","sym":"LRL"},
    {"office":"New England","addr":"New England District\n Regulatory Division\n 696 Virginia Rd\n Concord, MA 01742","phone":"978-318-8338","web":"http://www.nae.usace.army.mil/Missions/Regulatory.aspx","sym":"NAE"},	
    {"office":"Philadelphia","addr":"Philadephia District\n 100 Penn Square East\n Wanamaker Bldg\n Philadelphia, PA 19107-3390","phone":"215-656-6728","web":"http://www.nap.usace.army.mil/Missions/Regulatory.aspx","sym":"NAP"},	
    {"office":"Sacramento","addr":"Sacramento Regulatory Office\n 1325 J Street\n Room 1350\n Sacramento, CA 95814","phone":"916-557-5250","web":"http://www.spk.usace.army.mil/Missions/Regulatory.aspx","sym":"SPK"},
    {"office":"Chicago","addr":"Chicago District Regulatory Branch\n 231 South LaSalle Street, Suite 1500\n Chicago, Illinois 60604","phone":"312-846-5530","web":"http://www.lrc.usace.army.mil/Missions/Regulatory.aspx","sym":"LRC"},
    {"office":"Pittsburgh","addr":"Pittsburgh District\n Moorhead Federal Bldg\n 1000 Liberty Ave, Ste 2200\n Pittsburgh, PA 15222","phone":"412-395-7155","web":"http://www.lrp.usace.army.mil/Missions/Regulatory.aspx","sym":"LRP"},
    {"office":"Rock Island","addr":"Rock Island District\n Clock Tower Bldg\n 1500 Rock Island Dr\n Rock Island, IL  61201","phone":"309-794-5376","web":"http://www.mvr.usace.army.mil/Missions/Regulatory.aspx","sym":"MVR"},
    {"office":"New York","addr":"New York District\n ATTN: Regulatory Branch, Room 1937\n 26 Federal Plaza\n New York, NY 10278-0090","phone":"917-790-8511","web":"http://www.nan.usace.army.mil/Missions/Regulatory.aspx","sym":"NAN"},
    {"office":"Portland","addr":"Portland District Regulatory Office\n 333 SW First Ave\n PO Box 2946\n Portland, OR 97204-3495","phone":"503-808-4373","web":"http://www.nwp.usace.army.mil/Missions/Regulatory.aspx","sym":"NWP"},
    {"office":"Detroit","addr":"Detroit District\n 477 Michigan Ave, Room 603\n Detroit, MI 48226-2550","phone":"313-226-2218","web":"http://www.lre.usace.army.mil/Missions/RegulatoryProgramandPermits.aspx","sym":"LRE"},
    {"office":"Omaha","addr":"Omaha District Regulatory Office\n 1616 Capitol Avenue, Suite 9000\n Omaha, NE 68102-9000","phone":"402-995-2469","web":"http://www.nwo.usace.army.mil/Missions/RegulatoryProgram.aspx","sym":"NWO"},	
    {"office":"Walla Walla","addr":"Walla Walla District Office\n 201 N 3rd Ave\n Walla Walla, WA 99362-1876","phone":"509-527-7150","web":"http://www.nww.usace.army.mil/BusinessWithUs/RegulatoryDivision.aspx","sym":"NWW"},	
    {"office":"Seattle","addr":"Seattle District Corps of Engineers\n CENWS-OD-RG\n P.O. Box 3755\n Seattle, WA 98124-3755","phone":"206-764-3495","web":"http://www.nws.usace.army.mil/Missions/CivilWorks/Regulatory.aspx","sym":"NWS"},	
    {"office":"St. Paul","addr":"St. Paul District Office\n 180 5th Street East, Suite 700\n St. Paul, Minnesota 55101-1678","phone":"651-290-5525","web":"http://www.mvp.usace.army.mil/Missions/Regulatory.aspx","sym":"MVP"},	
    {"office":"Alaska","addr":"Alaska District Office\n PO Box 6898\n Jber, Alaska 99506-0898","phone":"907-753-2712","web":"http://www.poa.usace.army.mil/Missions/Regulatory.aspx","sym":"POA"}	
    ]
    
    let getContactData=function(officesym){
        for(let i=0;i<contactData.length;i++){
            if(officesym===contactData[i].sym){
                return contactData[i];
            }
        }
    }
    
    export {getContactData};