import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';
import {OrmEvents} from '../OrmEvents.js';
import {SearchResult} from './SearchResult.js';
import scrollIntoView from "scroll-into-view";
import { findDOMNode } from "react-dom";

class SearchResults extends Component{

    constructor(props){
        super(props);
        this.state={'highlight':true}
        OrmEvents.addListener('onMapItemHover', (e) => {
            this.setState({'highlight':true});
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedIdentifier && this.props.selectedIdentifier!==prevProps.selectedIdentifier){
            this.scrollTo(this.props.selectedIdentifier);
        }
    }
    
    /*
    setHighlight=(val)=>{
        this.setState({'highlight':val});
    }
    */

    getHighlight=()=>{
        return this.state.highlight;
    }
    
    scrollTo = (identifier) => {
        const node = findDOMNode(this.refs[identifier]);
        scrollIntoView(node, {
          time: 500,
          align: {
            top: 0
          }
        })
    }

    render(){
        let self=this;
        let results=(this.props.searchResult)?this.props.searchResult.results.features:[];
        return(
            <div className='SearchResults' onMouseEnter={()=>{this.setState({'highlight':false})}}>
            {results.map(function(obj, index){
                return <SearchResult 
                        key={index}
                        index={index} 
                        result={results[index]}
                        ref={results[index].properties.identifier}
                        selectedIdentifier={self.props.selectedIdentifier}
                        //highlight={self.state.highlight}
                        getHighlight={self.getHighlight}
                        //selectPermit={self.props.selectPermit}
                        />;
            })}
            </div>
        );
    }
}

export default connect(
    'selectSearchResult',
    'selectSelectedIdentifier',
    SearchResults
);