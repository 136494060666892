import React,{Component} from 'react';
import { connect } from 'redux-bundler-react';
import {OrmEvents} from '../../OrmEvents.js';
import {FinalPermit} from '../permits/FinalPermit.js';
import {PendingPermit} from '../permits/PendingPermit.js';
import {EmergencyPermit} from '../permits/EmergencyPermit.js';
import {DwhsPermit} from '../permits/DwhsPermit.js';
import {AjdPermit} from '../permits/AjdPermit.js';
import {NepaEa} from '../permits/NepaEaPermit.js';
import {NepaEis} from '../permits/NepaEisPermit.js';
import {SearchResult} from '../SearchResult.js';

const APIHOST = process.env.REACT_APP_APIHOST;

class PermitActions extends Component{

    constructor(props){
        super(props);
        this.state={results:[]};
    }

    permitSort(a,b){
        let d1=Date.parse(a.properties.displayDate);
        let d2=Date.parse(b.properties.displayDate);
        if (d1<d2) 
            return 1;
        if (d1>d2)
            return -1;
        return 0;
    }

    componentDidMount=()=>{
        this.doSearch(this.props.selectedIdentifier);
    }

    doSearch=(daNumber)=>{
        let xhr = new XMLHttpRequest();
        let geodataUrl=`${APIHOST}/permits/search?max=25&sa=true&q=identifier:"`+daNumber+'"';
        xhr.open('GET', geodataUrl);
        xhr.onreadystatechange = function(){
        if (xhr.readyState > 3 && xhr.status === 200) {
            console.log(xhr.responseText);
            let searchresults = JSON.parse(xhr.responseText);
            let features=searchresults.results.features.sort(this.permitSort);
            this.setState({"results":features});
        }
        }.bind(this);
        xhr.send();
    }

    componentWillReceiveProps=(nextProps)=>{
        if(this.props.selectedIdentifier!==nextProps.selectedIdentifier){
            this.doSearch(nextProps.selectedIdentifier);
        }
    }

    onClick=(e)=>{
        OrmEvents.emit('searchPanelHide', false);
    }

    renderPermit(){
        let self=this;
        let daNumber="";
        let district="";
        let projectName="";
        let applicant="";
        if(this.state.results.length>0){
            let p=this.state.results[0].properties;
            daNumber=p.daNumber
            district=p.district
            projectName=p.projectName;
            applicant=p.applicant;
        }
        return(
            <div className="PermitActions">
                <div className='inline' onClick={this.onClick} style={{"cursor":"pointer"}}>
                    
                    <a>BACK TO SEARCH RESULTS</a>
                </div>
                <br/>
                <br/>
                <div className="SearchResultsTable">
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">DA Number:</div><div className="PermitActionsTableCellValue">{daNumber}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Project Name:</div><div className="PermitActionsTableCellValue">{projectName}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Applicant</div><div className="PermitActionsTableCellValue">{applicant}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">District:</div><div className="PermitActionsTableCellValue">{district}</div></div>
                </div>
                <div style={{"height": "25px"}}><em><b>History of Actions:</b></em></div>
                <div style={{"width":"100%","height":"1px","borderBottom":"1px solid gray"}}/>
                <div className='PermitActionResults'>
                    {this.state.results.map(function(obj, index){
                        switch(obj.properties.vtype){
                            case 'pending':{
                                return <PendingPermit
                                    result={self.state.results[index]}
                                />
                            }
                            break;
                            case 'emergency':{
                                return <EmergencyPermit
                                    result={self.state.results[index]}
                                />
                            }
                            break;
                            case 'dwhs':{
                                return <DwhsPermit
                                    result={self.state.results[index]}
                                />
                            }
                            break;
                            case 'jds':{
                                return <AjdPermit
                                    result={self.state.results[index]}
                                />
                            }
                            break;
                            //Final and WRDA render the same. 
                            default:{
                                return <FinalPermit
                                    result={self.state.results[index]}
                                />
                            }
                        }
                    
                    })}
                </div>
            </div>
        )
    }

    renderS408(){
        let self=this;
        let requestName="";
        let regulatoryPermitId="";
        let locationName="";
        let reviewCategory="";
        let civilWorksProjects="";
        let status="";
        let org="";
        let identifier="";
        let initialSubmissionDate="";
        let lastSubmissionDate="";

        if(this.state.results.length>0){
            let p=this.state.results[0].properties;
            requestName=p.requestName;
            regulatoryPermitId=p.regulatoryPermitId;
            locationName=p.locationName;
            reviewCategory=p.reviewCategory;
            civilWorksProjects=p.civilWorksProjects;
            status=p.status;
            org=p.org;
            identifier=p.identifier;
            initialSubmissionDate=p.initialSubmissionDate;
            lastSubmissionDate=p.latestSubmissionDate;
        }
        return(
            <div className="PermitActions">
                <div className='inline' onClick={this.onClick} style={{"cursor":"pointer"}}>
                    
                    <a>BACK TO SEARCH RESULTS</a>
                </div>
                <br/>
                <br/>
                <div className="SearchResultsTable">
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Request ID</div><div className="PermitActionsTableCellValue">{identifier}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Request Name</div><div className="PermitActionsTableCellValue">{requestName}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Location Name</div><div className="PermitActionsTableCellValue">{locationName}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Organization</div><div className="PermitActionsTableCellValue">{org}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Status</div><div className="PermitActionsTableCellValue">{status}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Civil Works Projects</div><div className="PermitActionsTableCellValue">{civilWorksProjects}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Review Category</div><div className="PermitActionsTableCellValue">{reviewCategory}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Reg Permit Id</div><div className="PermitActionsTableCellValue">{regulatoryPermitId}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Initial Submission Date</div><div className="PermitActionsTableCellValue">{initialSubmissionDate}</div></div>
                <div className="SearchResultsTableRow"><div className="PermitActionsTableHeading">Last Submission Date</div><div className="PermitActionsTableCellValue">{lastSubmissionDate}</div></div>
                </div>                
            </div>
        )
    }

    /*


And then when viewing permit details include:
USACE Civil Works Project(s) (CIVIL_WORKS_PROJECTS) Review Category (REQUEST_REVIEW_CATEGORY) Regulatory Permit ID (N/A if no related Reg permit) (REGULATORY_PERMIT_ID) Initial Request Received Date (INITIAL_SUBMISSION_DATE) Latest Submission Date (LAST_SUBMISSION_DATE)

    */

    render(){
        console.log(this.state.results);
        return(
            (this.state.results.length>0 && this.state.results[0].properties.vtype==='s408')?
            this.renderS408()
            :
            this.renderPermit()
        );
    }
}

//<div className='inline'  dangerouslySetInnerHTML={{'__html':SearchResult.backArrowIcon}}/> 

export default connect(
    'selectSelectedIdentifier',
    PermitActions
)