import {OrmEvents} from '../OrmEvents.js';

const APIHOST = process.env.REACT_APP_APIHOST;

export class PermitsLayer{

    constructor(map,vtype,max){
        this.map=map;
        this.vtype=vtype;
        this.max=max;
        this.xhr=null;
        this.disposing=false;
    }

    static styles={
        "issued":{color:"#00FF00",text:"Final IP"},
        "pending":{color:"#FF8E43",text:"Pending IP"},
        "nepa_ea":{color:"#00FFFF",text:"NEPA EA"},
        "nepa_eis":{color:"#ff0000",text:"NEPA EIS"},
        "emergency":{color:"#FF5EBC",text:"Emergency"},
        "wrda":{color:"#B565EB",text:"WRDA"},
        "dwhs":{color:"#FFFF00",text:"DWHS"},
        "jds":{color:"#0000FF",text:"AJD"},
        "s408":{color:"#6a5acd",text:"Section 408"},

    }

    loadData=(bbox)=>{
        let xhr= this.xhr = new XMLHttpRequest();
        let geodataUrl=`${APIHOST}/permits/search?da=true&max=`+this.max+`&bbox=`+this.map.getBounds().toBBoxString();
        if(this.vtype && this.vtype!=='all'){
            geodataUrl=`${APIHOST}/permits/search?da=true&max=`+this.max+"&bbox="+this.map.getBounds().toBBoxString()+"&q=vtype:"+this.vtype;
        }
        xhr.open('GET', geodataUrl);
        xhr.onreadystatechange = ()=>{
            if (xhr.readyState > 3 && xhr.status === 200) {
                let searchresult = JSON.parse(xhr.responseText);
                let geojson=searchresult.results;
                let geojsonMarkerOptions = {
                        radius: 6,
                        //fillColor: "#ff0000",
                        stroke:true,
                        weight:2,
                        color: "#bbbbbb",
                        //weight: 0.2,
                        opacity: 1,
                        fillOpacity: 1
                    };
                if(!this.disposing){ //@TODO dont think I need this....but everything works so well right now....
                    
                    this.layer=window.L.geoJson(geojson,{
                        pointToLayer: (feature, latlng)=> {
                            geojsonMarkerOptions.fillColor=PermitsLayer.styles[feature.properties.vtype].color;
                            return window.L.circleMarker(latlng, geojsonMarkerOptions);
                        },
                        onEachFeature:this.onEachFeature
                    }).bindTooltip((layer)=>{
                        return this.toolTipBuilder(layer);
                    }).addTo(this.map);
                    
                }
            }
        };
        xhr.send();
    
    }

    onEachFeature=(feature,layer)=>{
        layer.on({
            click: (e)=>{
                OrmEvents.emit('highlightAndFlyToPermit', {'permit':e.target.feature,'forceFly':false});
                OrmEvents.emit('showPermitActions', e.target.feature);
            },
            mouseover:(e)=>{
                let layer = e.target;
                
                layer.setStyle({
                    radius: 6,
                    fillColor: "#375FCF",
                    stroke:true,
                    weight:2,
                    color: "#bbbbbb",
                    opacity: 1,
                    fillOpacity: 0.8
                });
            
                if (!window.L.Browser.ie && !window.L.Browser.opera && !window.L.Browser.edge) {
                    layer.bringToFront();
                }
            },
            mouseout:(e)=>{
                this.layer.resetStyle(e.target);
            }
        });
    }

    toolTipBuilder=(layer)=>{
        var props=layer.feature.properties;
        //let countText = (' ('+props.count+' permit')+((parseInt(props.count)>1)?'s)':')');
        let name=(props.projectName===undefined)?props.requestName:props.projectName;
        var html=props.identifier+'<br>'+ name + '<br>';
        return html;
    }

    dispose=()=>{
        this.disposing=true;
        if(this.xhr && this.xhr.readyState<4){
            this.xhr.abort();
        }
        if(this.layer){
            this.layer.remove();
        }
    }
}