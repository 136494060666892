import React,{Component} from 'react';
import {HexbinLegend} from './HexbinLegend.js';
import {PermitLegend} from './PermitLegend.js';

export class Legend extends Component{

    render(){
        return(
            <div>
                {this.props.legendToggle==='hexbin'?(
                    <HexbinLegend
                        hexbinLayer={this.props.hexbinLayer}
                    />
                ):(
                    <PermitLegend/>
                )}
            </div>
        );
    }
}