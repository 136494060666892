import {getVtype,getVName,initialView,dateToYMD} from '../VTypeUtils';


const CHANGE_MODE = 'CHANGE_MODE';
const CHANGE_MODE_STATE='CHANGE_MODE_STATE';
const CHANGE_NATURAL_DISASTERS='CHANGE_NATURAL_DISASTERS';
const CHANGE_INITIAL_VIEW='CHANGE_INITIAL_VIEW';
const CHANGE_SELECTED_IDENTIFIER='CHANGE_SELECTED_IDENTIFIER';
const AGREE='AGREE';
const ORM_INDEX_JOB_DATE='ORM_INDEX_JOB_DATE';
const GET_ORM_AS_OF_DATE = 'GET_ORM_AS_OF_DATE';

const APIHOST = process.env.REACT_APP_APIHOST;

export default {
    name:'root',
    getReducer(){
        const initialState = {
            mode:'home',
            events:null,  //natural disaster events
            vtype:'all',
            selectedIdentifier:null,
            ormIndexJobDate: "Not Available",
            getOrmAsOfDate: "Not Available",
        }

        return (state = initialState, {type, payload}) => {
          switch(type){
            case CHANGE_MODE:
            case CHANGE_MODE_STATE:
            case CHANGE_INITIAL_VIEW:
            case CHANGE_NATURAL_DISASTERS:
            case CHANGE_SELECTED_IDENTIFIER:
            case AGREE:
            case ORM_INDEX_JOB_DATE:
            case GET_ORM_AS_OF_DATE:
              return({...state,...payload});
            default:
              return state;
          }
        }
    },

    doChangeMode:(mode)=> ({dispatch,store}) => {
        dispatch({type:CHANGE_MODE,payload:{"mode":mode}});
    },

    doChangeModeState:(payload)=> ({dispatch,store}) => { //more generic than above.  Intended to accept change for mode,vtype
        if(payload.mode==='map'){
            store.doClearSort();
            store.doUpdateDa(true);    
        }
        else if(payload.mode==='table'){
            store.doUpdateDa(false);
        }
        debugger
        dispatch({"type":CHANGE_MODE,"payload":payload});
    },

    doChangeSelectedIdentifier:(val)=>({dispatch,store})=>{
        dispatch({"type":CHANGE_SELECTED_IDENTIFIER,"payload":{"selectedIdentifier":val}});
    },

    doAgree:()=>({dispatch,store})=>{
        dispatch({type:AGREE,payload:{"mode":"map","vtype":"all"}});
    },

    doGetNaturalDisasterEventList:()=>({dispatch,store})=>{
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `${APIHOST}/orm/events`);
        xhr.onreadystatechange = function(){
            if (xhr.readyState > 3 && xhr.status === 200) {
                let events = JSON.parse(xhr.responseText);
                events.sort();
                dispatch({type:CHANGE_NATURAL_DISASTERS,payload:{"events":events}})
                store.doSetInitialView();
            }
        }
        xhr.send();
    },
   
    doGetOrmIndexJobDate:()=>({dispatch,store})=>{
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `${APIHOST}/orm_index_job_date`);
        xhr.onreadystatechange = function(){
            if (xhr.readyState > 3 && xhr.status === 200) {
                let wkDate = xhr.responseText;
                dispatch({type:ORM_INDEX_JOB_DATE,payload:{"ormIndexJobDate":wkDate}});
            }
        }
        xhr.send();
    },

    doGetOrmAsOfDate:()=>({dispatch,store})=>{
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `${APIHOST}/get_orm_as_of_date`);
        xhr.onreadystatechange = function(){
            if (xhr.readyState > 3 && xhr.status === 200) {
                let wkDate = xhr.responseText;
                let wkDated = dateToYMD(wkDate)
                dispatch({type:GET_ORM_AS_OF_DATE,payload:{"getOrmAsOfDate":wkDated}});
            }
        }
        xhr.send();
    },
    
    doSetInitialView:()=>({dispatch,store})=>{
        const iv = initialView();
        let mode=(iv.mode || "home");
        let vtype=(iv.vtype || "all");
        let q=(iv.q || "");
        let org=(iv.org || null);
        let year=(iv.year || null);
        let month=(iv.month || null);
        if(q!=="" && mode==="home"){
            mode="map";
        }
        if(vtype!=="all"){
            if(mode==="home")
                mode="map";
        }
        dispatch({type:CHANGE_INITIAL_VIEW,payload:{"mode":mode,"vtype":vtype}});
        store.doChangeQ({"q":q});
        if(org) { store.doUpdateFilterParam("org",org) }
        if(year) { store.doUpdateFilterParam("year",year) }
        if(month) { store.doUpdateFilterParam("month",month) }
        //store.doSearch();
    },

    selectMode:(store)=>{
        console.log("SELECTING MODE");
        return store.root.mode;
    },

    selectVtype:(store)=>{
        console.log("SELECTING VTYPE");
        return store.root.vtype;
    },

    selectSelectedIdentifier:(store)=>{
        console.log("SELECTING SELECTEDIDENTIFIER");
        return store.root.selectedIdentifier;
    },

    selectDisasterEvents:(store)=>{
        console.log("SELECTING NATURAL DISASTER EVENTS");
        return store.root.events;
    },

    selectOrmIndexJobDate:(store)=>{
        console.log("SELECTING ORM INDEX JOB DATE");
        return store.root.ormIndexJobDate;
    },

    selectGetOrmAsOfDate:(store)=>{
        console.log("SELECTING ORM AS OF DATE");
        return store.root.getOrmAsOfDate;
    }


}