import { composeBundles } from 'redux-bundler';
import rootBundle from './root-bundle';
import searchBundle from './search-bundle';
import mapBundle from './map-bundle';


export default composeBundles(
    rootBundle,
    searchBundle,
    mapBundle
);