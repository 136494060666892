import React,{Component} from 'react';
import AutoComplete from '../autocomplete/AutoComplete.js';

const APIHOST = process.env.REACT_APP_APIHOST;
const PUBLIC_URL = process.env.PUBLIC_URL;
const GEOCODER_URL=process.env.REACT_APP_MAPBOX_HOST
const GEOCODER_TOKEN=process.env.REACT_APP_MAPBOX_TOKEN

export class Geocoder extends Component{

    state={
        "clearButton":false
    }

    parser = (data) => {
        if(data.features){
          return data.features;
        }else{
          return [];
        }
    }

    onSelect=(e)=>{
        this.props.setGeocoderMarker(e);
        this.props.setView(e.center,15);
        this.setState({"clearButton":true});
    }

    onClear=()=>{
        this.props.clearGeocoderMarker();
        this.setState({"clearButton":false});
        this.geocoderAutocomplete.clear();    
    }
    

    render(){
        let geocoderUrl=`${GEOCODER_URL}/{query}.json?country=us&access_token=${GEOCODER_TOKEN}`;
        
        return(
            <div className="Geocoder">
                <div style={{"height":"7px"}} />
                <AutoComplete 
                    ref = { (el) => { this.geocoderAutocomplete = el } }
                    apiParser={ this.parser }
                    caseSensitive={ false }
                    displayKey={ 'place_name' }
                    onSelect={ this.onSelect }
                    placeholder={ 'Find an address or city' }
                    url={ geocoderUrl }
                    inputStyle={{
                        "fontSize":"11px",
                        "height":"24px"
                    }}
                />
                {(this.state.clearButton)?(
                    <div className="ClearGeocode" onClick={this.onClear} title="Clear Location">
                        <img src={`${PUBLIC_URL}/close-grey.png`} style={{"height":"15px"}}/>
                    </div>
                    ):null
                }
            </div>
        );
    }
}