import React, {Component} from 'react';
import { connect } from 'redux-bundler-react';
import {IssuedGrid,PendingGrid,EmergencyGrid,DwhsGrid,AjdGrid,WrdaGrid,S408Grid,NepaEaGrid,NepaEisGrid} from './PermitGrid.js';
import {SearchResult} from '../search/SearchResult.js';
import {OrmEvents} from '../OrmEvents.js';
import QueryBuilder from '../search/QueryBuilder';
import _ld  from 'lodash';
//var CSV = require("./Csv.js");

class TableView extends Component{
    
    constructor(props){
        super(props);
        let data;
        if(this.props.searchResult.results.features.length>0){
          data =this.featuresToAttributeArray(this.props.searchResult.results.features);
        }
        else{
          data=[];
        }
        this.state={'data':data,'filteredRecordCount':0}
        //this.maxRecords=10000;
       
    }

  
    componentDidMount=()=>{
      OrmEvents.emit('onSelectedDaNumber', null); //reset initial query value
      //this.query(this.props.vtype);
    }

    
    componentWillReceiveProps=(newProps)=>{
      console.log("IS Search the same:"+(this.props.searchResult===newProps.searchResult));
      if(this.props.searchResult===newProps.searchResult){
        console.log("the same...")
      }
      else{
        console.log("Table got new search results.....");
        let data=this.featuresToAttributeArray(newProps.searchResult.results.features);
        this.setState({"data":data,'filteredRecordCount':newProps.searchResult.results.features.length});
      }
    }
    
    featuresToAttributeArray=(features)=>{
      let a=[];
      features.forEach(function(feature) {
        let data=feature.properties
        if(feature.geometry){
          data['lon']=feature.geometry.coordinates[0];
          data['lat']=feature.geometry.coordinates[1];
        }
        a.push(data);
      }, this);
      return a;
    }
    
    renderTable(){
      if(this.state.data.length>0){
        switch(this.props.vtype){
          case "issued":
            return(
              <IssuedGrid
                data={this.state.data}
                setFilteredRecordCount={this.setFilteredRecordCount}
              />
            )
          case "pending":
            return(
              <PendingGrid
                data={this.state.data}
                setFilteredRecordCount={this.setFilteredRecordCount}
              />
            )
          case "emergency":
            return(
              <EmergencyGrid
                data={this.state.data}
                setFilteredRecordCount={this.setFilteredRecordCount}
              />
            )
          case "dwhs":
          return(
            <DwhsGrid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          )
          case "wrda":
          return(
            <WrdaGrid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          )
          case "jds":
          return(
            <AjdGrid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          )
          case "s408":
          return(
            <S408Grid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          ) 
          case "nepa_ea":
          return(
            <NepaEaGrid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          )    
          case "nepa_eis":
          return(
            <NepaEisGrid
              data={this.state.data}
              setFilteredRecordCount={this.setFilteredRecordCount}
            />
          )    
          default:
            console.log("Unknown vtype: "+this.props.vtype);
        }
      }
      else{
        return(
          <div className="SearchNoResult">
            <em>The search returned no data.</em>
          </div>
        );
      }
    }

    setFilteredRecordCount=(val)=>{
      this.setState({'filteredRecordCount':val});
    }

    dataDownload=function(){
      OrmEvents.emit('exportCsv', null);
    }.bind(this);

    renderDownloadAndSummary(){
      let recs=this.state.data.length;
      let plural=(this.state.filteredRecordCount>1)?"s":"";
      return(
        <div className="DownloadAndSummary">
        <div className="table">
          <div className="tableRow">
            <div style={{"cursor":"pointer"}} className="tableItem">
            {recs>0?(
              <div onClick={this.dataDownload} dangerouslySetInnerHTML={{'__html':SearchResult.downloadIcon}}/>
            ):null} 
            </div>
            <div className="tableItem" style={{"textAlign": "right"}}><em>
              {recs>0?(
                "Showing "+this.state.filteredRecordCount+ " Permit"+plural+" of "+recs
              ):null}
              </em> 
            </div>
          </div>
        </div>  
      </div>
      );  
    }

    //{this.renderQueryForm()}
    render() {
      return (
        <div className="TableView">
          {this.renderTable()}
        </div>
      );
    }
}

export default connect(
  'selectSearchResult',
  'selectVtype',
  TableView
);