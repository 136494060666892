import {OrmPermit} from './OrmPermit.js';

export class PendingPermit extends OrmPermit{

    constructor(props){
        super(props);
        this.fields={
            dateReceived:"Federally Complete",
            publicNoticeDate:"Public Notice Date"
        }
    }
}