import React, { Component } from 'react';
import { connect } from 'redux-bundler-react';
import ReactDOM from 'react-dom';
import '@corpsmap/corpsmap_argon';
import {HexbinLayer} from './HexbinLayer.js';
import {Legend} from './Legend.js';
import SearchLayer from '../search/SearchLayer';
import {PermitsLayer} from './PermitsLayer.js';
import {OrmEvents} from '../OrmEvents.js';
import BasemapLayers from './BasemapLayers';
import {Geocoder} from './Geocoder.js';
import GeoFenceControl from './GeoFenceControl.js';
import {zoomToFeatures} from './GisUtils.js';

const PUBLIC_URL = process.env.PUBLIC_URL;

class Map extends Component{

    constructor(props){
        super(props);
        this.state={'hexbinLayer':null,'legendToggle':'hexbin',"searchArea":false};
        //window.L.Icon.Default.imagePath = '/img/';
        window.L.Icon.Default.imagePath = `${PUBLIC_URL}/`;
        this.registerIcons();
        this.searchLayer=null;
        this.panelItemClickEvent = OrmEvents.addListener('highlightAndFlyToPermit', (e) => {
            let bounds=this.map.getBounds();
            let featureLocation=this.showSelectedFeature(e.permit);
            if(e.forceFly || !bounds.contains(featureLocation)){
                this.map.flyTo(featureLocation,12);
            }
        });

        this.newSearchResultEvent = OrmEvents.addListener('onNewSearchResult', (permit) => {
            this.showSelectedFeature(permit);
        });

        this.clearSelectedItem = OrmEvents.addListener('onClearSelectedItem', () => {
            if(this.selectedMarker)
                this.selectedMarker.remove();
        });

        this.removeMarkerEvent = OrmEvents.addListener('onRemoveMarker', () => {
            if(this.selectedMarker){
                this.selectedMarker.remove();
            }
        });

    }

    showSelectedFeature=(feature)=>{
        if(feature.geometry!==null){
            let c=feature.geometry.coordinates;
            let featureLocation=window.L.latLng(c[1],c[0]);
            let toolTip=this.toolTipBuilder(feature.properties);
            if(this.selectedMarker){
                this.selectedMarker.off('click');
                this.selectedMarker.remove();
            }
            this.selectedMarker=window.L.marker(featureLocation,{'feature':feature,icon:this.selectedIcon,zIndexOffset:1000}).addTo(this.map)
                .bindTooltip(toolTip);
            this.selectedMarker.on('click', this.selectedMarkerClick)
            return featureLocation;
        }
    }

    selectedMarkerClick=(e)=>{
        OrmEvents.emit('showPermitActions', e.sourceTarget.options.feature);
    }

    toolTipBuilder=(properties)=>{
        var html;
        if(properties.vtype === 's408') {
            let name=(properties.projectName===undefined)?properties.requestName:properties.projectName;
            let locationName=(properties.locationName===undefined)?properties.locationName:properties.locationName;
            html=properties.identifier+'<br>'+ name + '<br>' + locationName + '<br>';
        }
        else {
            let name=(properties.projectName===undefined)?properties.requestName:properties.projectName;
            html=properties.identifier+'<br>'+ name + '<br>';
        }
        
        return html;
    }

    registerIcons=()=>{
        this.selectedIcon = window.L.icon({
            iconUrl: `${PUBLIC_URL}/marker-icon-selected.png`,
            shadowUrl: `${PUBLIC_URL}/marker-shadow.png`,
            iconSize:    [25, 41],
            iconAnchor:  [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize:  [41, 41]
        });

        this.pinIcon = window.L.icon({
            iconUrl: `${PUBLIC_URL}/pin.png`,
            shadowUrl: `${PUBLIC_URL}/pin-shadow.png`,
            iconSize:    [41, 41],
            iconAnchor:  [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize:  [71, 41]
        });
    }

    componentDidMount=()=>{
        this.initializeMap();
    }

    componentWillUnmount=()=>{
        let mb={
            "center":this.map.getCenter(),
            "zoom":this.map.getZoom()
        }
        this.props.doSaveMapBounds(mb);

        if(this.map.geoFence.isActive()){
            this.props.doSaveFenceBounds(this.map.geoFence.getShape());
        }
        else{
            this.props.doSaveFenceBounds(null);
        }
        this.props.doUpdateMap(null);
    }

    initializeMap=()=>{
        var map=this.map=window.L.corpsmap.map(ReactDOM.findDOMNode(this), {
            renderer: window.L.canvas(),
            cm_basemaps: [],
            cm_datasets: [],
            attributionControl: true,
            copyLonLatControl: false,
            fullscreenControl: false,
            geocoderControl: false,
            homeControl: false,
            identifyControl: false,
            layersControl: false,
            measureControl: true,
            overviewControl: false,
            preview: false,
            scaleControl: false,
            titleControl: false,
            zoomControl: true,
            zoomdisplayControl: false,
            center: [39, -96],
            zoom: 5,
            theme: 1
          });  

        this.props.doUpdateMap(map); //send a map reference upstream
        
        map.getSearchBounds=()=>{
            let bounds;
            if(map.geoFence.isActive()){
                console.log("fence:")
                console.log(map.geoFence.getBounds());
                bounds=map.geoFence.getBounds();
            }
            else{
                console.log("map:")
                console.log(map.getBounds())
                bounds=map.getBounds();
            }
            return bounds;
        }

        map.on('moveend',(e)=>{
            console.log("Zoom Level:" +map.getZoom());
            const zoom=map.getZoom();
            if(zoom>=11){
                this.hexbinLayer.hide();
                this.showPermitLayer(this.props.vtype);
                this.setState({"legendToggle":"permit"});
                if(!map.geoFence.isControl){
                    map.geoFence=new GeoFenceControl();
                    map.addControl(map.geoFence);
                }
            }
            else{
                this.removePermitLayer();
                this.hexbinLayer.show(this.props.vtype);
                this.setState({"legendToggle":"hexbin"});
                if(map.geoFence.isControl){
                    map.removeControl(map.geoFence);
                    if(this.selectedMarker){
                        this.selectedMarker.remove();
                    }
                    map.geoFence={"isActive":()=>false,isControl:false};
                }
            }
            //this.props.setLatLngBounds(map.getSearchBounds());
            if(this.props.searchResult && this.props.searchResult.total>0){
                this.setState({"searchArea":true});
            }
        });

        if(this.props.fenceBounds){
            map.geoFence=new GeoFenceControl();
            map.addControl(map.geoFence);
            map.geoFence.reenable(this.props.fenceBounds);
        }
        else{
            this.map.geoFence={"isActive":()=>false,iscontrol:false};
        }
        this.hexbinLayer=new HexbinLayer(map);
        this.hexbinLayer.hide();
        this.setState({'hexbinLayer':this.hexbinLayer});
        this.createSearchLayer(this.props.searchResult.results);
        const mb=this.props.mapBounds;
        map.setView(mb.center,mb.zoom);
    }

    componentWillUnmount() {
        if(this.map){
            window.L.corpsmap.previousView={};
            window.L.corpsmap.previousView.latLng=this.map.getCenter();
            window.L.corpsmap.previousView.zoom=this.map.getZoom();
        }
    }

    

    setGeocoderMarker=(e)=>{
        const location=e.center;
        this.geocoderMarker = window.L.marker([location[1],location[0]],{icon:this.pinIcon})
                                      .addTo(this.map)
                                      .bindTooltip(e.place_name);
    }

    clearGeocoderMarker=()=>{
        if(this.geocoderMarker)
            this.geocoderMarker.remove();    
    }

    setView=(lonlat,z)=>{
        if(this.map){
            this.map.flyTo(lonlat.reverse(),z);
        }
    }

    /*
    getMap=()=>{
        return this.map;
    }
    */

    createSearchLayer=(geojson)=>{
        if(this.searchLayer)
            this.searchLayer.clear();
        if(geojson.features.length>0){
            this.searchLayer=new SearchLayer(this.map,geojson);
        }
    }

    componentWillReceiveProps=(nextProps)=>{
        
        if(this.props.searchResult!==nextProps.searchResult){
            this.createSearchLayer(nextProps.searchResult.results);
        }

        if(this.props.vtype!==nextProps.vtype){
            if(this.selectedMarker)
                this.selectedMarker.remove(); //reset selected marker
            if(this.map.getZoom()>=11){
                this.showPermitLayer(nextProps.vtype);
            }
            else{
                this.hexbinLayer.show(nextProps.vtype);
            }
        }
        //this.searchLayer=new SearchLayer(this.props.map,results);
    }

    showPermitLayer=(vtype)=>{
        this.removePermitLayer();
        this.permitsLayer=new PermitsLayer(this.map,vtype,1000);
        this.permitsLayer.loadData();    
    }

    removePermitLayer=()=>{
        if(this.permitsLayer){
            this.permitsLayer.dispose();
        }
    }
    
    doSearch=()=>{
        this.setState({"searchArea":false});
        OrmEvents.emit('doSearch');
    }
    

    render() {
        return (
          <div id='map' className="map" style={{"height":"100%"}}>
              <BasemapLayers
                //getMap={this.getMap} 
              />
              <Legend
                hexbinLayer={this.state.hexbinLayer}
                legendToggle={this.state.legendToggle}
              />
              <Geocoder
                setView={this.setView}
                setGeocoderMarker={this.setGeocoderMarker}
                clearGeocoderMarker={this.clearGeocoderMarker}
              />
              {this.state.searchArea?(
                  <div className="SearchArea" onClick={this.doSearch}>
                      Search Area
                  </div>
                ):null}
          </div>
        );
      }
}

export default connect(
    'doUpdateMap',
    'doSaveMapBounds',
    'doSaveFenceBounds',
    'selectMapBounds',
    'selectFenceBounds',
    'selectVtype',
    'selectSearchResult',
    Map
);

