
export default class QueryBuilder{

    constructor(params){
        this.queryParams={...params};
        this.q=params.q;
        let dash = '-';
        this.dashFind = new RegExp(dash, 'g');
    }

    buildQuery=()=>{
        if(!this.q){
            this.q="*:*";    
        }
        else{
            let q1=this.q.replace(this.dashFind,'ZZ');
            let q2=this.q.replace(this.dashFind,' ');
            this.q=q1+"* AND *"+q2+"*";
        }
    
        if(this.queryParams.vtype!==undefined && this.queryParams.vtype!=='all'){
            this.q="vtype:"+this.queryParams.vtype+" AND "+this.q;
        }    

        switch(this.queryParams.vtype){
            case 'pending':
                return this.orgPart()
                            .build();

            case 'emergency':
                return this.eventPart()
                            .build();

            case 'issued': 
            case 'wrda':
            case 'dwhs': 
            case 'jds':
            case 's408':
            case 'nepa_ea':
            case 'nepa_eis':
            case 'all':
                return this.orgPart()
                            .datePart()
                            .build();
            default:
                return this.build();
        }
    }

    build=()=>{
        return this.q;
    }

    eventPart=()=>{
        if(this.queryParams.event!==undefined){
            this.q+='%20AND%20event:"'+encodeURI(this.queryParams.event)+'"';  
        }
        return this;
    }

    orgPart=()=>{
        if(this.queryParams.org!==undefined && this.queryParams.org!=="")
            this.q+="%20AND%20org:"+this.queryParams.org;
        return this;
    }

    datePart=()=>{
        if(this.queryParams.year!="ALL"){
            if(this.queryParams.year!==undefined){
                if(this.queryParams.month===undefined || this.queryParams.month==="0")
                    this.q+="%20AND%20vdate:["+this.queryParams.year+"0101%20TO%20"+this.queryParams.year+"1231]";
                else
                    this.q+="%20AND%20vdate:["+this.queryParams.year+this.queryParams.month+"01%20TO%20"+this.queryParams.year+this.queryParams.month+"28]";
            }
        } 
        return this;
    }

}

  